import React, { useEffect, useState } from "react";
import Control from 'react-leaflet-custom-control';
import ReactDOMServer from 'react-dom/server';
import {MapContainer, Marker, useMap, useMapEvents, GeoJSON, ScaleControl} from 'react-leaflet';
import { MapContainerProps } from "react-leaflet/types/MapContainer";
import { useTranslation } from "react-i18next";
import { LeafletMapConfig } from "../../../manager/map/config";
import { ReactComponent as SvgQuestion } from "./img/question.svg";
import { ReactComponent as SvgHome } from "./img/home.svg";
import MarkerIcon from "./img/marker-icon.png";
import NetworkIcon from "./img/reseau.png";
import LegendConform from "./img/legend_conform.png";
import "./leaflet.scss";
import "leaflet/dist/leaflet.css";
import L, {LatLngLiteral} from "leaflet";
import LeafletRuler from "./ruler/LeafletRuler";
import { connect } from "react-redux";
import {MapActions, ReportActions, SearchActions, UserActions} from "../../../store/redux/actions";
import { updateVisibleLayers, setMapPreferences } from "../../../store/redux/actions/user";
import Request, { HTTPMethods }  from "../../../services/Request";
import "./tree/L.Control.Layers.Tree";
import "./tree/L.Control.Layers.Tree.d.ts";
import "./tree/L.Control.Layers.Tree.css";
import { ISearch } from "../../../store/redux/types";
import { SEARCH } from "../../../store/redux/constants";
import { store } from "../../../store";
import { categories } from "../../../config/constants";
import { ReactComponent as CrossSvg } from "../../../assets/img/icons/cross.svg";
import { ReactComponent as CircleBlue } from "../../../assets/img/circle-blue.svg";
import { ReactComponent as CircleBlack } from "../../../assets/img/circle-black.svg";
import { ReactComponent as CircleBlackOutlineGreen } from "../../../assets/img/circle-black-outline-green.svg";
import { ReactComponent as CircleBlackOutlineRed } from "../../../assets/img/circle-black-outline-red.svg";
import { wfsIcons } from "./patrimoineIcons";
import { AssetLayer } from "../../../types";
import { conformity } from "../../../config/report";

interface ILeafletMapComponent extends MapContainerProps {
    zooms: any,
    center: any,
    mapInfo: any,
    contract: any,
    setMapInfo: (payload: any) => void;
    getMapPreferences?: any;
    search?: ISearch;
    showDetail: any;
    showDetailControl: any;
    showReport: any;
    hideReport: any;
}

interface ILeafletLocateComponent {
    locateClicked: number,
    setLocateClicked: (locateClicked: any) => void;
}

interface MapInfoInterface {
    mapInfo: any,
    setMapInfo: (payload: any) => void;
}

interface HighlightInterface {
    point: LatLngLiteral|undefined,
    show: boolean,
    type: string,
    id: string,
    line: any,
}

export type LayerObject = {
    layer: any,
    label: string
}

export type groupObject = {
    selectAllCheckbox: boolean,
    label: string,
    children: LayerObject[],
}

function LocateComponent(props: ILeafletLocateComponent) {
    const map1 = useMap();
    if (props.locateClicked > 0) {
        map1.locate();
        props.setLocateClicked(0);
    }
    const map = useMapEvents({
        locationfound: (location) => {
            map.flyTo(location.latlng);
            const radius = location.accuracy;
            const circle = L.circle(location.latlng, radius);
            circle.addTo(map);
        },
        locationerror: (error) => {
            console.log(error.message);
        },
    });
    return null;
}

function mathCeilFloorWithRoundingPrecision(value: number, operation = 'floor', valIncrement = 0.01) {
    if ('ceil' === operation) {
        return Math.ceil(value / valIncrement) * valIncrement;
    } else {
        return Math.floor(value / valIncrement) * valIncrement;
    }
}

function GetBounds(props: any) {
    const { setLatLng, setBounds, setBoundsNE, setBoundsSW, setZoom, setMapInfo } = props;
    const map = useMapEvents({
        zoomend: () => {
            const zoom = map.getZoom();
            // console.log(zoom)
            setZoom(zoom);
            setMapInfo({
                zoom: zoom,
                autoZoom: false,
            });
            setTimeout(() => {
                setMapPreferences({ zoom: map.getZoom() });
            }, 300);
        },
        moveend: () => {
            try {
                const center = map.getCenter();
                const lat = center.lat;
                const lng = center.lng;
                const bounds = map.getBounds();
                const zoom = map.getZoom();
                setZoom(zoom);
                setLatLng(center);
                setBounds(bounds);
                setMapInfo({
                    center: {
                        lat: lat,
                        lng: lng,
                    },
                    autoZoom: false,
                });
                setTimeout(() => {
                    setMapPreferences({
                        center: {
                            lat: lat,
                            lng: lng,
                        },
                    });
                }, 600);
                map.invalidateSize();

                if (bounds) {
                    const urlEpsgNE = LeafletMapConfig.API_HOST + "/geoserver/srs/transform/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getNorthEast().lat, 'ceil') + "/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getNorthEast().lng, 'ceil');
                    Request.get(urlEpsgNE).then((responseData: any) => {
                        setBoundsNE(responseData.data);
                    });
                    const urlEpsgSW = LeafletMapConfig.API_HOST + "/geoserver/srs/transform/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getSouthWest().lat) + "/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getSouthWest().lng);
                    Request.get(urlEpsgSW).then((responseData: any) => {
                        setBoundsSW(responseData.data);
                    });
                }
            } catch (e) {
                console.log('srs transform error')

                return;
            }
        },
    });

    return null;
}

function ResizeMap(mapInfoInterface: MapInfoInterface) {
    const map = useMap();
    const {mapInfo, setMapInfo} = mapInfoInterface;
    if (mapInfo.resize) {
        setTimeout(() => {
            map.invalidateSize();
            setMapInfo({
                resize: false,
            });
        }, 10);
    }

    return null;
}

const LeafletMapComponent = (props: ILeafletMapComponent) => {
    const [map, setMap] = useState<any|null>(null);
    const [doZoom, setDoZoom] = useState<boolean>(false);
    const [pref, setPref] = useState<any>(null);
    let defaultSelectedLayers: any = [];
    let comLayers: any = [];
    let intercomLayers: any = [];
    const { children, zooms, mapInfo, contract, search,
        setMapInfo, showDetail, showDetailControl, getMapPreferences, showReport, hideReport,
        ...rest } = props;
    let center = props.center;
    if (mapInfo.zoom !== 0) {
        zooms.zoom = mapInfo.zoom;
    }
    if (mapInfo.center !== null) {
        center = [mapInfo.center.longitude, mapInfo.center.latitude]
    }

    const contractId = contract.id;
    const assetLayers = contract.assetLayers;
    let assetLayersWithKey: any = {};
    Object.keys(assetLayers).map((key: any) => {
        let layerKey = assetLayers[key]['layer'];
        assetLayersWithKey[layerKey] = assetLayers[key];
    });
    const baseLayers = contract.baseLayers;
    let patrimoineLayers: string[] = [];
    assetLayers.map((layer: AssetLayer) => {
        if ('Patrimoine' === layer['group']) {
            patrimoineLayers.push(layer['layer']);
        }
    });
    const conformityLayers: any = [
        'conformite_conforme',
        'conformite_nonConforme',
        'conformite_unknown',
    ];

    const wfsPatrimoineLayers: any = [
        'A_POMPAG_point', //ok
        'A_DEVERS_point', //ok
        'A_TRAPHY_point', //ok
        'A_CAPTEU_point', // ok
        'A_CONDEB_point', // ok
        'A_CHASSE_point', // ok
        'T_SITE_polygon',
        'A_STEP_point', // ok
        'A_VENTOU_point', // ok
        'A_BASSIN_point', // ok
        'A_BOUCHE_point', // 2549 ok mais long
        // 'A_SECOUL_point', // 11882
    ];

    const mixPatrimoineLayers: any = [
        'A_COLLEC_line',
        'A_BRANCH_line',
        'A_REGARD_point', // 16776
        'A_REGBRA_point', // 10920
        'A_RACCOR_point',
        'T_PLT_point',
    ];

    const { t } = useTranslation();
    const [showLegend, setShowLegend] = useState(false);

    // System of showing all sublayers of filter MO = com/intercom (For client siahvy)
    // Configuration in heimdall contract_metadata moFilter
    const moFilter = contract.contractMetadatas?.moFilter === 1;
    const [showFilterMo, setShowFilterMo] = useState(false);
    const [comActive, setComActive] = useState(false);
    const [intercomActive, setIntercomActive] = useState(false);

    const [showHighlight, setShowHighlight] = useState(false);
    const [highlightPoint, setHighlightPoint] = useState<any>(false);
    const [highlightCircle, setHighlightCircle] = useState<any>(false);
    const [getFeature, setGetFeature] = useState<any>(false);
    const [highlightId, setHighlightId] = useState('');
    const [highlightType, setHighlightType] = useState('');
    const [geojson, setGeojson] = useState<any>(false);
    const [refreshing, setRefreshing] = useState<any>(false);
    const [latLng, setLatLng] = useState<any>();
    const [zoom, setZoom] = useState<any>();
    const [bounds, setBounds] = useState<any>();
    const [boundsNE, setBoundsNE] = useState<any>(); // coordonnées en EPSG2154
    const [boundsSW, setBoundsSW] = useState<any>(); // coordonnées en EPSG2154
    const [locateClicked, setLocateClicked] = useState<number>(0);
    const [highlightLineWeight, setHighlightLineWeight] = useState<number>(8);
    const [activeLayers, setActiveLayers] = useState<any>([]);
    const [activePatrimoineLayers, setActivePatrimoineLayers] = useState<any>([]);
    const [conformiteLayers, setConformiteLayers] = useState<any>(false);
    const [comLayersGroup, setComLayersGroup] = useState<any>(L.layerGroup([]));
    const [intercomLayersGroup, setIntercomLayersGroup] = useState<any>(L.layerGroup([]));
    const osm_uri = LeafletMapConfig.OSM_URI as string;
    const geoserverUrlBase = LeafletMapConfig.geoserverWmsHost + "/geoserver/uuid-" + contractId;
    const apiUrlBase = LeafletMapConfig.API_HOST + "/api/" + contractId;
    const geoserverFeatureUrlBase = geoserverUrlBase + "/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application%2Fjson&srsName=EPSG:4326";
    const geoserverGetFeatureUrlBase = geoserverUrlBase + "/wms?service=WMS&version=1.1.1&request=GetFeatureInfo";
    const geoserverLegendUrl = geoserverUrlBase + "/wms?service=WMS&version=1.1.0&request=GetLegendGraphic&height=12&weight=12&srs=EPSG:4326&styles&format=image/png&layer=";

    let highlightLineStyle = {
        color: "#66ffff",
        weight: highlightLineWeight,
        opacity: 1,
        radius: 8,
        fillOpacity: 0,
        smoothFactor: 1
    };

    const [layerGeojson, setLayerGeojson] = useState<any>({
        conformity: {},
        patrimoine: {},
    });

    const CircleBlueHtml = ReactDOMServer.renderToString(<CircleBlue/>);

    const CrossSvgHtml = ReactDOMServer.renderToString(<CrossSvg/>);

    let highlightIcon;

    const circleIcon = L.divIcon({
        html: CircleBlueHtml,
        className: "highlightPoint",
        iconSize: [30, 30],
        iconAnchor: [12, 10]
    });

    const crossIcon = L.divIcon({
        html: CrossSvgHtml,
        className: "highlightPoint",
        iconSize: [12, 12],
        iconAnchor: [24, 24]
    });

    highlightIcon = crossIcon;

    const osmLayer = L.tileLayer(osm_uri, {
        ...zooms,
        attribution: 'Map data &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, CC-BY-SA',
    });

    let baseLayerChildren = [{
        selected: true,
        label: 'OpenStreetMap',
        layer: osmLayer,
        key: "osmLayer",
    }];

    baseLayers.map((baseLayer: { layer: any; uri: string; label: any; key: string;}) => {
        let layerOptions = {
            maxZoom: LeafletMapConfig.ZOOM_MAX,
            maxNativeZoom: LeafletMapConfig.MAX_NATIVE_ZOOM,
        };
        let baseLayerObject = L.tileLayer.wms('https://data.geopf.fr/wms-r/wms?crs=EPSG:3857&version=1.3.0&layers='+baseLayer.layer, layerOptions);
        baseLayerChildren.push({
            selected: true,
            label: baseLayer.label,
            key: baseLayer.key,
            layer: baseLayerObject,
        });
    });

    const baseTree = {
        label: 'Galerie de fonds de plan',
        children: baseLayerChildren
    };

    const firstLogin = mapInfo.firstLogin;
    const treeOptions = {
      // collapsed: false, // Keep open
      closedSymbol: '➤',
      openedSymbol: '▼',
    };

    function coordsToLatLng (coords: [number, number] | undefined) {
        if (undefined !== coords) {
            const latlng: LatLngLiteral = {
                lat: coords[1],
                lng: coords[0]
            }
            return latlng;
        }
        return undefined;
    }

    function doShowHighlight (highlight: any) {
        if (highlight.show) {
            setShowHighlight(highlight.show);
            if (highlight.point !== undefined) {
                setHighlightCircle("conformity" === highlight.type);
                setGeojson(false);
                setHighlightPoint(highlight.point);
            } else {
                setHighlightPoint(false);
                if (highlight.line !== undefined) {
                    setGeojson(highlight.line);
                    setHighlightId(highlight.id);
                    setHighlightType(highlight.type);
                }
                else if (highlightId !== highlight.id || highlightType !== highlight.type) {
                    let geojsonurl = geoserverFeatureUrlBase + "&typeName=uuid-" + contractId + ":" + highlight.type + "&CQL_FILTER=ASSET_ID=%27" + highlight.id + "%27";
                    Request.sendRequest(geojsonurl, HTTPMethods.GET, {}, false, true)
                        .then((res: { data: any }) => {
                            let geojsonResponse = res.data;
                            delete geojsonResponse.crs;
                            setGeojson(geojsonResponse);
                            setHighlightId(highlight.id);
                            setHighlightType(highlight.type);

                            return geojsonResponse;
                        });
                }
            }
        }
    }

    function refreshActiveLayers (save = false) {
        if (refreshing) {
            return;
        }
        setRefreshing(true);
        let layers: string[] = [];
        let pLayers: string[] = []; // Temporary collection for active Patrimoine layers

        conformityLayers.map((conformityLayer: string) => {
            if (undefined !== layerGeojson['conformity'][conformityLayer]) {
                map.removeLayer(layerGeojson['conformity'][conformityLayer]);
            }
        });
        setConformiteLayers(false);

        wfsPatrimoineLayers.map((wfsPatrimoineLayer: string) => {
            if (undefined !== layerGeojson['patrimoine'][wfsPatrimoineLayer]) {
                map.removeLayer(layerGeojson['patrimoine'][wfsPatrimoineLayer]);
            }
        });
        //
        mixPatrimoineLayers.map((mixPatrimoineLayer: string) => {
            if (undefined !== layerGeojson['patrimoine'][mixPatrimoineLayer]) {
                map.removeLayer(layerGeojson['patrimoine'][mixPatrimoineLayer]);
            }
        });

        // if ('conformity' === mapInfo.highlight.type) {
        //     conformityLayers.map((conformityLayer: string) => {
        //         if (undefined !== layerGeojson['conformity'][conformityLayer]) {
        //             layerGeojson['conformity'][conformityLayer].addTo(map);
        //         }
        //     });
        //     setConformiteLayers(true);
        // }

        // if ('conformity' === mapInfo.highlight.type) {
        //     conformityLayers.map((conformityLayer: string) => {
        //         if (undefined !== layerGeojson['conformity'][conformityLayer]) {
        //             map.removeLayer(layerGeojson['conformity'][conformityLayer]);
        //             layerGeojson['conformity'][conformityLayer].addTo(map);
        //         }
        //     });
        //     setConformiteLayers(true);
        // } else {
        //     conformityLayers.map((conformityLayer: string) => {
        //         if (undefined !== layerGeojson['conformity'][conformityLayer]) {
        //             map.removeLayer(layerGeojson['conformity'][conformityLayer]);
        //         }
        //     });
        //     setConformiteLayers(false);
        // }
        Object.keys(map._layers).map((key) => {
           const activeLayer = map._layers[key];
           const layerName = activeLayer.options.layers;
           const _url = activeLayer._url;
           let url, activeLayerFilterTypo: string;
           if (_url !== undefined) {
               url = _url.toString();
           } else {
               return false;
           }
           if (url !== undefined) {
               const indexFilter = url.indexOf('TYPO=%27');
               activeLayerFilterTypo = url.substring(indexFilter+8, indexFilter+14);
           }

           let indexName: any;
           if (layerName !== undefined) {
               indexName = layerName.split(":")[1];
               if (
                   conformityLayers.indexOf(indexName) !== -1
                   && undefined !== layerGeojson['conformity'][indexName]
               ) {
                   layerGeojson['conformity'][indexName].addTo(map);
                   setConformiteLayers(true);
               }
               // RAF: gérer les subgroup
               if (
                   wfsPatrimoineLayers.indexOf(indexName) !== -1
                   && undefined !== layerGeojson['patrimoine'][indexName]
               ) {
                   layerGeojson['patrimoine'][indexName].addTo(map);
               }

               if (
                   mixPatrimoineLayers.indexOf(indexName) !== -1
                   && undefined !== layerGeojson['patrimoine'][indexName]
                   && map.getZoom() > 16
               ) {
                   layerGeojson['patrimoine'][indexName].addTo(map);
               }
           }

           if (layerName !== undefined && !layers.includes(layerName)) {
               Object.keys(assetLayers).map((key: any) => {
                   const layer = assetLayers[key];
                   const hasSubGroup = layer['metadata']['hasSubGroup'] !== undefined ?? false;
                   const filterTypo = layer['metadata']['filterTypo'] ?? false;
                   if (layer['layer'] === indexName && !hasSubGroup) {
                       let layerNameWithFilter = layerName;
                       if (filterTypo) {
                           if (filterTypo === activeLayerFilterTypo) {
                               layerNameWithFilter = layerName + ':' + filterTypo;
                           }
                           if (-1 === layers.indexOf(layerNameWithFilter)) {
                               layers.push(layerNameWithFilter);
                           }
                       } else if (-1 === layers.indexOf(layerName)) {
                           layers.push(layerName);
                       }
                       if (-1 !== patrimoineLayers.indexOf(indexName) && -1 === pLayers.indexOf(indexName)) {
                           pLayers.push(indexName);
                       }
                   }
                   return true;
               });
           }
           return true;
        });
        setActiveLayers(layers);
        setActivePatrimoineLayers(pLayers);

        if (save && 0 !== layers.length) {
            setMapInfo({
                assetLayers: layers
            });
            setTimeout(() => {
                updateVisibleLayers(layers);
                setRefreshing(false);
            }, 1000);
        }
        setTimeout(() => {
            updateVisibleLayers(layers);
            setRefreshing(false);
        }, 1000);
    }

    function getColor(feature: any, indexName: any){
        const properties = feature.properties;
        if (indexName === "A_BRANCH_line") {
            switch(properties.STATUT_PAT) {
                case 'EN SERVICE':
                    switch(properties.RESEAU) {
                        case 'EU':
                            return '#ff0000';
                        case 'EP':
                            return '#00ff00';
                        case 'UN':
                            return '#ce23b1';
                    }
                    return '#000000';
                case 'EN PROJET':
                case 'PROJET':
                    return '#a6cee3';
                case 'DEPOSE':
                    return '#ff7f00';
                default:
                    return '#0000ff';
            }
        }
        if (indexName === "A_COLLEC_line") {
            if (properties.ECOUNORM === "REFOUL") {
                return '#0000ff';
            }
            switch(properties.STATUT_PAT) {
                case 'EN SERVICE':
                    switch(properties.RESEAU) {
                        case 'EU':
                            return '#ff0000';
                        case 'EP':
                            return '#00ff00';
                        case 'UN':
                            return '#ce23b1';
                    }
                    return '#0000ff';
                case 'EN PROJET':
                    return '#a6cee3';
                case 'DEPOSE':
                    return '#ff7f00';
                default:
                    return '#0000ff';
            }
        }
        return '#000000';
    }

    function getWeight(feature: any, indexName: any){
        if (indexName === "A_BRANCH_line") {
            return 3;
        }
        if (indexName === "A_COLLEC_line") {
            if (feature.properties.MO === "INTERCOM") {
                return 3;
            }
            return 2;
        }
        return 3;
    }

    function getOpacity(feature: any, indexName: any){
        if (feature.properties.STATUT_PAT === 'HORS SERVICE') {
            return 0;
        }
        return 1;
    }

    function getDashArray(feature: any, indexName: any){
        if (indexName === "A_BRANCH_line") {
            return '3,3';
        }
        if (indexName === "A_COLLEC_line") {
            if (feature.properties.ECOUNORM === "REFOUL") {
                return '10 4';
            }
            return '';
        }
        return '3,3';
    }

    function renderWfsPatrimoineLayer(layerUrl: string, layer: AssetLayer, boundsSW: any, boundsNE: any, zoom: any) {
        // après démarrage et après chaque zoom/déplacement sur la carte, charger les assets wfs selon position
        const key = layer['layer'];
        let layerKey = `uuid-${contractId}:${key}`;
        const isWfsPatrimoineLayer = -1 !== wfsPatrimoineLayers.indexOf(key);
        const isMixPatrimoineLayer = -1 !== mixPatrimoineLayers.indexOf(key);

        if (isMixPatrimoineLayer && boundsNE === null && boundsSW === null) {
            return;
        }
        if (isMixPatrimoineLayer && zoom < 16) {
            return;
        }
        layerUrl = layerUrl + '&version=1.0.0';
        layerUrl = layerUrl + '&request=GetFeature';
        layerUrl = layerUrl+ '&maxFeatures=2000';
        layerUrl = layerUrl+ '&typeName=' + layerKey;
        layerUrl = layerUrl+ '&outputFormat=application%2Fjson';
        layerUrl = layerUrl+ '&srsName=EPSG:4326';
        if (boundsNE && boundsSW) {
            layerUrl = layerUrl+ '&CQL_FILTER=BBOX(geom,'+boundsNE.x+','+boundsNE.y+','+boundsSW.x+','+boundsSW.y+')';
        }
        try {
            Request.get(layerUrl).then(async (responseData: any) => {
                const wfsPatrimoineGeojson = L.geoJSON(responseData.data, {
                    style: function (feature: any) {
                        let indexNameFragments = feature.id.split(".");
                        const indexName = indexNameFragments[0];
                        let className = '';
                        if (indexName === "A_COLLEC_line") {
                            if (feature.properties.HAUTEUR !== undefined) {
                                className = feature.properties.HAUTEUR;
                            }
                        }
                        return {
                            color: getColor(feature, indexName),
                            weight: getWeight(feature, indexName),
                            opacity: getOpacity(feature, indexName),
                            dashArray: getDashArray(feature, indexName),
                            lineJoin: 'round'
                        };
                    },
                    pointToLayer: function (feature: any, latlng) {
                        let opacity = 1;
                        let reseau: string = feature.properties.RESEAU;
                        if (reseau === null) reseau = "A";
                        if (reseau === "INCONNU") {
                            reseau = "A";
                            opacity = 0;
                        }
                        if (reseau === "ET") reseau = "A"; // VENTOU
                        let options = {};
                        if (undefined !== wfsIcons[key] && undefined !== wfsIcons[key][reseau]) {
                            options = {
                                icon: wfsIcons[key][reseau],
                                opacity: opacity
                            };
                        }
                        return L.marker(latlng, options);
                    },
                    onEachFeature: function (feature, layer) {
                        layer.unbindTooltip();
                        if (feature !== undefined && feature.id !== undefined && typeof feature.id === "string") {
                            let indexNameFragments = feature.id.split(".");
                            const indexName = indexNameFragments[0];
                            if (
                                indexName === "A_REGARD_point"
                                && zoom > 17
                                && feature.properties.STATUT_PAT !== 'HORS SERVICE'
                                && '420d59de-c3ed-11ed-a0a0-0242ad15150b' === contractId
                            ) {
                                let ident: string = feature.properties?.IDENT;
                                let identTail = ident.substring(19);
                                setTimeout(() => {
                                    layer.bindTooltip(identTail, {
                                        direction: 'right',
                                        permanent: true,
                                        offset: [10, 0],
                                        className: 'regard-tooltip'
                                    });
                                }, 2000);
                            }
                            // if (indexName === "A_COLLEC_line" && zoom > 17 && feature.properties.STATUT_PAT !== 'HORS SERVICE') {
                            //     let hauteur: string = feature.properties?.HAUTEUR;
                            //     setTimeout(() => {
                            //         layer.bindTooltip(hauteur, {
                            //             direction: 'right',
                            //             permanent: true,
                            //             offset: [10, 0],
                            //             className: 'collec-tooltip'
                            //         });
                            //     }, 2000);
                            // }
                        }

                        layer.on({
                            click: function (e) {
                                hideReport();
                                const feature = e.target.feature;
                                const assetId = feature.properties.ASSET_ID;
                                let indexNameFragments = feature.id.split(".");
                                const indexName = indexNameFragments[0];
                                const geometry = feature.geometry;
                                const type = geometry.type;
                                const coordinates = geometry.coordinates;
                                let point: LatLngLiteral | undefined = undefined;
                                let latlng = undefined;
                                let highlight: HighlightInterface = {
                                    show: true,
                                    type: indexName,
                                    id: assetId,
                                    point: point,
                                    line: undefined,
                                };
                                if (type === 'Point') {
                                    point = coordinates;
                                    latlng = coordsToLatLng(coordinates);
                                    highlight.point = latlng;
                                    setMapInfo({
                                        center: latlng,
                                        extent: null,
                                        highlight: highlight,
                                        autoZoom: true,
                                    });
                                } else {
                                    if ("A_COLLEC_line" === indexName && feature.properties.MO === "INTERCOM") {
                                        setHighlightLineWeight(16);
                                    } else {
                                        setHighlightLineWeight(8);
                                    }
                                    highlight.line = feature;
                                    setMapInfo({
                                        extent: feature.bbox,
                                        highlight: highlight,
                                        autoZoom: true
                                    });
                                }
                                const layerAssetId = indexName.toLowerCase() + '_' + assetId;
                                const itemName = t(indexName.toLowerCase(), {ns: 'asset'}) + " " + assetId;
                                const name = t("category.asset");
                                let search = props.search;
                                if (search !== undefined) {
                                    search.detail.shownDetailCategory = categories.ASSET;
                                    search.detail.shownDetailId = assetId;
                                }
                                showDetail(layerAssetId, search, itemName, name, "synthesis", true);
                                doShowHighlight(highlight);
                                setGetFeature(false);
                            },
                            mouseover: function (e) {
                                if (isWfsPatrimoineLayer) {
                                    const feature = e.target.feature;
                                    let indexNameFragments = feature.id.split(".");
                                    const indexName = indexNameFragments[0];
                                    const indexLabel = t(indexName.toLowerCase(), {ns: 'asset'});
                                    const properties = feature.properties;
                                    const coordinates = feature.geometry.coordinates;
                                    let popupContent = '<p class="asset-popup">' + indexLabel + '</p>';
                                    if (properties.NOM !== '' && properties.NOM !== null) {
                                        popupContent += '<p class="asset-popup">' + properties.NOM + '</p>';
                                    }
                                    if (properties.ADRESSE !== '' && properties.ADRESSE !== null) {
                                        popupContent += '<p>' + properties.ADRESSE + '</p>';
                                    }
                                    popupContent += '<p>' + properties.CODINSEE + ' ' + properties.COMMUNE + '</p>';
                                    layer.bindPopup(popupContent);
                                    layer.openPopup([coordinates[1], coordinates[0]]);
                                }
                            },
                            mouseout: function (e) {
                                if (isWfsPatrimoineLayer) {
                                    layer.closePopup();
                                }
                            },
                        })
                    }
                });

                // commenting this because dispear after a slash
                if (undefined !== layerGeojson['patrimoine'][key]) {
                    map.removeLayer(layerGeojson['patrimoine'][key]);
                }

                let layerGroup = new L.LayerGroup();
                layerGroup.addLayer(wfsPatrimoineGeojson);
                layerGeojson['patrimoine'][key] = layerGroup;

                setRefreshing(true);
                refreshActiveLayers();
            }).catch((e: any) => {
                // console.log(e)
                // console.log('get wfs error')
            }).finally(() => {
                // console.log('get wfs error')
            });
        } catch (e) {
            console.log('get wfs error');
        }
        setRefreshing(true);
        refreshActiveLayers();
    }

    function getLayerUrl(layer: AssetLayer) {
        const key = layer['layer'];
        const group = layer['group'];
        const filterTypo = layer['metadata']['filterTypo'] ?? false;
        const filterMo = layer['metadata']['filterMo'] ?? false;
        const filterReseau = layer['metadata']['filterReseau'] ?? false;
        const filterConformite = layer['metadata']['filterConformite'] ?? false;
        const subGroup = layer['metadata']['subGroup'] ?? false;
        const hasSubGroup = layer['metadata']['hasSubGroup'] === 'true' ?? false;
        const defaultSelected = layer['group'] === 'Patrimoine' || layer['layer'] === 'A_SECOUL_point';

        let layerKey = `uuid-${contractId}:${key}`;
        let layerUrl: string = geoserverUrlBase + '/wms?service=WMS&tiled=true';
        let layerUrlWfs: string = geoserverUrlBase + '/ows?service=WFS';

        const isGroupControl = group == 'Conformité des enquêtes';
        const isWfsPatrimoineLayer = -1 !== wfsPatrimoineLayers.indexOf(key);
        const isMixPatrimoineLayer = -1 !== mixPatrimoineLayers.indexOf(key);
        if (isGroupControl) {
            layerUrl = apiUrlBase + '/control/pdc?format=geojson&conformite=' + filterConformite;
        } else if (isWfsPatrimoineLayer) {
            layerUrl = layerUrlWfs;
        } else if (isMixPatrimoineLayer) {
            layerUrlWfs = geoserverUrlBase + '/ows?service=WFS';
        }
        // let layerName = layerKey;
        let filtered = false;
        if (filterTypo) {
            layerUrl += '&CQL_FILTER=TYPO=%27' + filterTypo +'%27';
            layerUrlWfs += '&CQL_FILTER=TYPO=%27' + filterTypo +'%27';
            filtered = true;
            // layerName = layerName + ':' + filterTypo;
        }
        // if (filterReseau) {
        //     if (filtered) {
        //         layerUrl += '%20AND%20RESEAU=%27' + filterReseau +'%27';
        //     } else {
        //         layerUrl += '&CQL_FILTER=RESEAU=%27' + filterReseau +'%27';
        //     }
        //     filtered = true;
        //     // layerName = layerName + ':' + filterReseau;
        // }
        // if (filterMo) {
        //     if (filtered) {
        //         layerUrl += '%20AND%20MO=%27' + filterMo +'%27';
        //     } else {
        //         layerUrl += '&CQL_FILTER=MO=%27' + filterMo +'%27';
        //     }
        //     // layerName = layerName + ':' + filterMo;
        // }

        return [layerUrl, layerUrlWfs];
    }

    useEffect(() => {
        // zoom and show highlight
        if (map) {
            const { highlight, autoZoom, firstLogin } = mapInfo;
            setDoZoom(autoZoom);
            if (autoZoom || doZoom) {
                const lng = map.getCenter().lng;
                const lat = map.getCenter().lat;
                const { center, extent } = mapInfo;
                if (extent !== null) {
                    map.fitBounds([
                        [extent[1], extent[0]],
                        [extent[3], extent[2]],
                    ]);
                    setTimeout(() => {
                        map.invalidateSize();
                    }, 10);
                    setDoZoom(false);
                } else if (center !== null && center['longitude'] !== lng && center['latitude'] !== lat) {
                    map.flyTo([center.lat, center.lng], LeafletMapConfig.MAX_NATIVE_ZOOM);
                    setTimeout(() => {
                        map.invalidateSize();
                    }, 10);
                    setDoZoom(false);
                }
                setDoZoom(false);
            }
            doShowHighlight(highlight);
            setShowHighlight(highlight.show);
            if ("conformity" === highlight.type && search?.flag.showResultDetail) {
                showDetailControl(highlight.id, props.search, highlight.id, t("pdc"), showReport);
            }

            if (firstLogin) {
                setMapInfo({
                    firstLogin: false,
                });
            }
        }
    }, [ mapInfo ]);

    useEffect(() => {
        // get assets in bbox vu

        if (undefined !== boundsSW && undefined !== boundsNE && !refreshing) {
            setRefreshing(true);
            reloadWfsLayers(zoom);
            refreshActiveLayers();
        }
    }, [ boundsSW, boundsNE, zoom ]);

    function reloadWfsLayers(zoom: number) {
        assetLayers.map((layer: AssetLayer) => {
            const key = layer['layer'];
            const wfsMinZoom = layer['metadata']['wfsMinZoom'] ?? 16;
            const isMixPatrimoineLayer = -1 !== mixPatrimoineLayers.indexOf(key);
            let [layerUrl, layerUrlWfs] = getLayerUrl(layer);

            const isActive = -1 !== activePatrimoineLayers.indexOf(key);
            if (isMixPatrimoineLayer && isActive && zoom >= wfsMinZoom) {
                renderWfsPatrimoineLayer(layerUrlWfs, layer, boundsNE, boundsSW, zoom);
            }
        });
    }

    function preprareOverlayTree(layerObjects: any) {
        let layerChildren: LayerObject[] = [];
        let groupChildren: groupObject[] = [];
        let overlaysTree = {
            label: 'Objets SIG',
            children: groupChildren,
        };

        Object.keys(layerObjects).map((key) => {
            // Patrimoine, Limit adminstratives, Curage, Autres
            let group = {
                label: key,
                selectAllCheckbox: true,
                children: layerChildren,
            };
            let subGroupObject: any = false; // Regarde, Branchement, Tronçons / Equipement de Régulation
            let sub2GroupObject: any = false; // EU, EP,                       / VANSEC, VANGUI
            let sub3GroupObject: any = false; //                               / EU, EP
            layerObjects[key].map((layerObject: { layer: any, label: string, subGroup?: boolean, hasSubGroup?: boolean }) => {
                if (layerObject.hasSubGroup) {
                    if (subGroupObject) {
                        if (sub2GroupObject) {
                            if (sub3GroupObject) {
                                if (sub3GroupObject.label !== layerObject.subGroup) {
                                    sub2GroupObject['children'].push(sub3GroupObject);
                                    sub3GroupObject = false;
                                    if (sub2GroupObject.label === layerObject.subGroup) {
                                        sub3GroupObject = {
                                            label: layerObject['label'],
                                            selectAllCheckbox: true,
                                            collapsed: true,
                                            children: [],
                                        };
                                    }
                                }
                            } else {
                                if (sub2GroupObject.label === layerObject.subGroup) {
                                    sub3GroupObject = {
                                        label: layerObject['label'],
                                        selectAllCheckbox: true,
                                        collapsed: true,
                                        children: [],
                                    };
                                }
                            }
                            if (sub2GroupObject.label !== layerObject.subGroup) {
                                subGroupObject['children'].push(sub2GroupObject);
                                sub2GroupObject = false;
                                if (layerObject.subGroup === false) {
                                    group['children'].push(subGroupObject);
                                    subGroupObject = {
                                        label: layerObject['label'],
                                        selectAllCheckbox: true,
                                        collapsed: true,
                                        children: [],
                                    };
                                } else {
                                    sub2GroupObject = {
                                        label: layerObject['label'],
                                        selectAllCheckbox: true,
                                        collapsed: true,
                                        children: [],
                                    };
                                }
                            }
                        } else {
                            sub2GroupObject = {
                                label: layerObject['label'],
                                selectAllCheckbox: true,
                                collapsed: true,
                                children: [],
                            };
                        }
                    } else {
                        subGroupObject = {
                            label: layerObject['label'],
                            selectAllCheckbox: true,
                            collapsed: true,
                            children: [],
                        };
                    }
                } else {
                    if (layerObject.subGroup) {
                        if (subGroupObject.label === layerObject.subGroup) {
                            if (sub2GroupObject) {
                                subGroupObject['children'].push(sub2GroupObject);
                                sub2GroupObject = false;
                            }
                            subGroupObject['children'].push(layerObject);
                        }
                        if (sub2GroupObject.label === layerObject.subGroup) {
                            sub2GroupObject['children'].push(layerObject);
                        }
                        if (sub3GroupObject.label === layerObject.subGroup) {
                            sub3GroupObject['children'].push(layerObject);
                        }
                    } else {
                        if (sub2GroupObject) {
                            subGroupObject['children'].push(sub2GroupObject);
                            sub2GroupObject = false;
                        }
                        if (subGroupObject) {
                            group['children'].push(subGroupObject);
                            subGroupObject = false;
                        }
                        delete layerObject.subGroup;
                        delete layerObject.hasSubGroup;
                        group['children'].push(layerObject);
                    }
                }
                return true;
            });
            overlaysTree['children'].push(group);
            layerChildren = [];

            return true;
        });

        return overlaysTree;
    }

    function renderGroupControl(layer: AssetLayer, layerUrl: string) {
        const key = layer['layer'];
        const conformityIconHtml: any = {
            conformite_conforme: ReactDOMServer.renderToString(<CircleBlackOutlineGreen/>),
            conformite_nonConforme: ReactDOMServer.renderToString(<CircleBlackOutlineRed/>),
            conformite_unknown: ReactDOMServer.renderToString(<CircleBlack/>),
        }
        const conformityIcon = L.divIcon({
            html: conformityIconHtml[key],
            className: "conformityPoint_" + layer.layer
        });

        Request.get(layerUrl).then(async (responseData: any) => {
            const conformityGeojson = L.geoJSON(responseData.data, {
                pointToLayer: function(feature,latlng){
                    return L.marker(latlng,{icon: conformityIcon});
                },
                onEachFeature: function (feature, layer) {
                    layer.on({
                        click: function (e){
                            hideReport();
                            const pdcId = e.target.feature.properties.idPDC;
                            const coordinates = e.target.feature.geometry.coordinates;
                            const latlng = coordsToLatLng(coordinates);
                            let highlight: { show: boolean; id: any; type: string; point?: LatLngLiteral } = {
                                show: true,
                                type: 'conformity',
                                id: pdcId,
                                point: latlng,
                            };
                            highlight.point = latlng;
                            setMapInfo({
                                center: latlng,
                                extent: null,
                                highlight: highlight,
                                autoZoom: true,
                            });
                            const layerAssetId = pdcId;
                            const itemName = pdcId;
                            const name = t("pdc");
                            let search = props.search;
                            if (search !== undefined) {
                                search.detail.shownDetailCategory = categories.ASSET;
                                search.detail.shownDetailId = pdcId;
                            }
                            showDetailControl(layerAssetId, search, itemName, name);
                            doShowHighlight(highlight);
                            setGetFeature(false);
                        },
                        mouseover: function (e){
                            const properties = e.target.feature.properties;
                            const coordinates = e.target.feature.geometry.coordinates;
                            layer.bindPopup(
                                '<p class="eca-popup">'+t("survey")+'</p>'+
                                '<p>'+properties.adresse+'</p>'+
                                '<p>'+properties.codePostal+' '+properties.commune+'</p>'
                            );
                            const latlng: LatLngLiteral = {
                                lat: coordinates[1],
                                lng: coordinates[0]
                            }
                            layer.openPopup(latlng);
                        },
                        mouseout: function (e){
                            layer.closePopup();
                        },
                    })
                }
            });

            let layerGroup = new L.LayerGroup();
            layerGroup.addLayer(conformityGeojson);
            layerGeojson['conformity'][key] = layerGroup;
            refreshActiveLayers();
        }).catch(e =>{
            // console.log(e);
            console.log('error wfs');
        });
    }

    useEffect(() => {
        if (map) {
            const center = map.getCenter();
            const bounds = map.getBounds();
            const zoom = map.getZoom();
            setZoom(zoom);
            setLatLng(center);
            setBounds(bounds);
            if (bounds) {
                try {
                    const urlEpsgNE = LeafletMapConfig.API_HOST + "/geoserver/srs/transform/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getNorthEast().lat, 'ceil') + "/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getNorthEast().lng, 'ceil');
                    Request.get(urlEpsgNE).then((responseData: any) => {
                        setBoundsNE(responseData.data);
                    });
                    const urlEpsgSW = LeafletMapConfig.API_HOST + "/geoserver/srs/transform/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getSouthWest().lat) + "/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getSouthWest().lng);
                    Request.get(urlEpsgSW).then((responseData: any) => {
                        setBoundsSW(responseData.data);
                    });
                } catch (e) {
                    console.log('srs transform error')

                    return;
                }
            }
            map.zoomControl.setPosition('bottomright');
            if (!pref) {
                let layerObjects: any = {};
                getMapPreferences().then((data: any) => {
                    let center = data.center;
                    if (center !== undefined && center !== {} && center !== null) {
                        const lat = center.lat ?? center.latitude;
                        const lng = center.lng ?? center.longitude;
                        map.flyTo([lat, lng], data.zoom);
                    }
                    setPref(data);
                    let defaultBaseLayer = osmLayer;
                    let selectedBaseLayer = data.mapBackground;
                    if (!firstLogin) {
                        selectedBaseLayer = mapInfo.baseLayer;
                    }
                    baseLayerChildren.map((baseLayerChild) => {
                        if (baseLayerChild.label === selectedBaseLayer) {
                            defaultBaseLayer = baseLayerChild.layer;
                        }
                        return true;
                    });
                    const layerGroup = L.layerGroup([defaultBaseLayer]);
                    layerGroup.addTo(map);

                    assetLayers.map((layer: AssetLayer, i: number) => {
                        const name = layer['metadata']['label'];
                        const key = layer['layer'];
                        const group = layer['group'];
                        const filterTypo = layer['metadata']['filterTypo'] ?? false;
                        const filterMo = layer['metadata']['filterMo'] ?? false;
                        const filterReseau = layer['metadata']['filterReseau'] ?? false;
                        const subGroup = layer['metadata']['subGroup'] ?? false;
                        const hasSubGroup = layer['metadata']['hasSubGroup'] === 'true' ?? false;
                        const defaultSelected = layer['group'] === 'Patrimoine' || layer['layer'] === 'A_SECOUL_point';
                        layerObjects[group] = layerObjects[group] ?? [];
                        const isGroupControl = group == 'Conformité des enquêtes';
                        const wfsMinZoom = layer['metadata']['wfsMinZoom'] ?? 16;
                        const layerKey = `uuid-${contractId}:${key}`;
                        const isWfsPatrimoineLayer = -1 !== wfsPatrimoineLayers.indexOf(key);
                        const isMixPatrimoineLayer = -1 !== mixPatrimoineLayers.indexOf(key);
                        let layerName = layerKey;
                        let [layerUrl, layerUrlWfs] = getLayerUrl(layer);
                        let opacity = 0.4;
                        if (group === "Patrimoine"|| layer['layer'] === 'A_SECOUL_point') {
                            opacity = 1;
                        }

                        let userLayerSelected = true;
                        if (data.visibleLayers && -1 === data.visibleLayers.indexOf(layerName)) {
                            userLayerSelected = false;
                        }
                        // When first login, show selected in default
                        let selected = userLayerSelected && (!hasSubGroup) && (!firstLogin || defaultSelected || isGroupControl);

                        let tileLayer, layerOptions;
                        if (isGroupControl) {
                            renderGroupControl(layer, layerUrl);
                            layerOptions = {
                                layers: layerKey,
                                maxZoom: LeafletMapConfig.ZOOM_MAX,
                                maxNativeZoom: LeafletMapConfig.MAX_NATIVE_ZOOM,
                            };

                            tileLayer = L.tileLayer.wms(layerUrl, layerOptions);
                        } else if (isWfsPatrimoineLayer) {
                            // calculate
                            renderWfsPatrimoineLayer(layerUrl, layer, null, null, zoom);

                            layerOptions = {
                                format: "image/png",
                                layers: layerKey,
                                transparent: true,
                                opacity: opacity,
                                maxZoom: LeafletMapConfig.ZOOM_MAX,
                                maxNativeZoom: LeafletMapConfig.MAX_NATIVE_ZOOM,
                            };

                            tileLayer = L.tileLayer.wms(layerUrl, layerOptions);
                        } else if (isMixPatrimoineLayer && zoom >= wfsMinZoom) {
                            // bbox too big 500 do not call without bounds
                            renderWfsPatrimoineLayer(layerUrlWfs, layer, null, null, zoom);

                            layerOptions = {
                                format: "image/png",
                                layers: layerKey,
                                transparent: true,
                                opacity: opacity,
                                maxZoom: wfsMinZoom,
                                maxNativeZoom: wfsMinZoom,
                            };

                            tileLayer = L.tileLayer.wms(layerUrl, layerOptions);
                        } else {
                            let maxNativeZoom = LeafletMapConfig.MAX_NATIVE_ZOOM;
                            if (layer['layer'] === 'A_SECOUL_point') {
                                maxNativeZoom = LeafletMapConfig.ZOOM_MAX;
                            }
                            layerOptions = {
                                format: "image/png",
                                layers: layerKey,
                                transparent: true,
                                opacity: opacity,
                                maxZoom: LeafletMapConfig.ZOOM_MAX,
                                maxNativeZoom: maxNativeZoom,
                            };

                            tileLayer = L.tileLayer.wms(layerUrl, layerOptions);
                        }

                        if (selected) {
                            defaultSelectedLayers.push(tileLayer);
                        }
                        if ('COM' === filterMo) {
                            comLayers.push(tileLayer);
                        }
                        if ('INTERCOM' === filterMo) {
                            intercomLayers.push(tileLayer);
                        }
                        layerObjects[group][i] = {
                            layer: tileLayer,
                            filterTypo: filterTypo,
                            filterMo: filterMo,
                            filterReseau: filterReseau,
                            label: name,
                            subGroup: subGroup,
                            hasSubGroup: hasSubGroup
                        };
                        return true;
                    });

                    setComLayersGroup(L.layerGroup(comLayers));
                    setIntercomLayersGroup(L.layerGroup(intercomLayers));

                    let overlaysTree = preprareOverlayTree(layerObjects);

                    if (defaultSelectedLayers) {
                        const defaultSelectedLayersGroup = L.layerGroup(defaultSelectedLayers)
                        try {
                            defaultSelectedLayersGroup.addTo(map);
                        } catch (e) {
                            // console.log(e);
                        }
                        L.control.layers.tree(baseTree, overlaysTree, treeOptions).addTo(map);
                    }
                    refreshActiveLayers();
                })
                .catch()
                {
                    // getMapPreferences error
                }
            }

            map.on('overlayadd', function() {
                setRefreshing(true);
                refreshActiveLayers(true);
            });
            map.on('overlayremove', function() {
                setRefreshing(true);
                refreshActiveLayers(true);
            });
            map.on('baselayerchange', function() {
                Object.keys(map._layers).map((key) => {
                    const treeName = map._layers[key]?._layersTreeName;
                    if (undefined !== treeName) {
                        setMapInfo({
                            baseLayer: treeName,
                        });
                        setMapPreferences({ baseMap: treeName });
                        return true;
                    } else {
                        return false;
                    }
                });
            });
            if (comLayers) {
                setComLayersGroup(L.layerGroup(comLayers));
            }

            if (intercomLayers) {
                setIntercomLayersGroup(L.layerGroup(intercomLayers));
            }
            refreshActiveLayers();
            doShowHighlight(mapInfo.highlight);
        }
    }, [map]);

    function goHome() {
        map.flyTo(props.center, LeafletMapConfig.ZOOM_DEFAULT);
    }

    function getAssetByClick(lat: number, lng: number){
        /**
         https://gis.stackexchange.com/questions/289111/how-to-add-meters-to-epsg4326-coordinates

         As a rule of thumb, for Paris :
         1 degree of latitude corresponds to 111km
         1 degree of longitude corresponds to 73km
         **/
        if (activePatrimoineLayers.length > 0 && !getFeature && !refreshing) {
            const sideLength = LeafletMapConfig.BBOX_SIDE_LENGTH as number; // 3 par défaut
            refreshActiveLayers();
            setGetFeature(true);
            const latExtent = sideLength/1110;
            const lngExtent = sideLength/730;
            const lat1 = lat - latExtent/2;
            const lat2 = lat + latExtent/2;
            const lng1 = lng - lngExtent/2;
            const lng2 = lng + lngExtent/2;
            let geoserverGetFeatureUrl = geoserverGetFeatureUrlBase;

            let layersString = '';
            activePatrimoineLayers.map((layer: any, i: number) => {
                if (i!==0) {
                    layersString += ',';
                }
                layersString += contractId + ':' + layer;
            });

            geoserverGetFeatureUrl += '&query_layers=' + layersString;
            geoserverGetFeatureUrl += '&layers=' + layersString;
            geoserverGetFeatureUrl += '&info_format=application/json';
            geoserverGetFeatureUrl += '&feature_count=1';
            geoserverGetFeatureUrl += '&x=50';
            geoserverGetFeatureUrl += '&y=50';
            geoserverGetFeatureUrl += '&srs=EPSG:4326';
            geoserverGetFeatureUrl += '&width=101';
            geoserverGetFeatureUrl += '&height=101';
            geoserverGetFeatureUrl += '&bbox='+lng1+","+lat1+","+lng2+","+lat2;
            store.dispatch({ payload: { flag: { loading: true } }, type: SEARCH.LOADING });

            // get result of asset around the point of click and go to the detail page
            Request.sendRequest(geoserverGetFeatureUrl, HTTPMethods.GET, {}, true, true)
              .then((res: { data: any }) => {
                  store.dispatch({ payload: { flag: { loading: false } }, type: SEARCH.LOADING });
                  if (res.data.numberReturned > 0) {
                      let feature = res.data.features[0];
                      // feature.id                 => sigIndexName             => indexName
                      // PAT_ASS.A_COLLEC_line.2729 => PAT_ASS.A_COLLEC_line    => A_COLLEC_line
                      // A_COLLEC_line.14815        => A_COLLEC_line            => A_COLLEC_line
                      const assetId = feature.properties.ASSET_ID;
                      let indexNameFragments = feature.id.split(".");
                      indexNameFragments.pop();
                      const sigIndexName = indexNameFragments.join('.');
                      const indexName = indexNameFragments.pop();
                      let point: any = undefined;
                      let latlng: LatLngLiteral|undefined = undefined;

                      let highlight: { show: boolean; id: any; type: any; point: LatLngLiteral|undefined } = {
                          show: true,
                          type: sigIndexName,
                          id: assetId,
                          point: point,
                      };
                      if (feature.geometry.type === 'Point') {
                          point = feature.geometry.coordinates;
                          latlng = coordsToLatLng(point);
                          highlight.point = latlng;
                          setMapInfo({
                              center: latlng,
                              extent: null,
                              highlight: highlight,
                              autoZoom: true,
                          });
                      } else {
                          setMapInfo({
                              extent: feature.bbox,
                              highlight: highlight,
                              autoZoom: true
                          });
                      }

                      const layerAssetId = indexName.toLowerCase() + '_' + assetId;
                      const itemName = t(indexName.toLowerCase(), { ns: 'asset'}) + " " + assetId;
                      const name = t("category.asset");
                      let search = props.search;
                      if (search !== undefined) {
                          search.detail.shownDetailCategory = categories.ASSET;
                          search.detail.shownDetailId = assetId;
                      }
                      showDetail(layerAssetId, search, itemName, name, "synthesis", true);
                      doShowHighlight(highlight);
                      setGetFeature(false);
                      hideReport();
                  }
              });
        }
        setTimeout(() => {
            setGetFeature(false);
        }, 1000);
    }

    function onChangeNetworkFilter(filter: any, active: any) {
        // show all com/intercom layers by toggle network filter
        if ('com' === filter) {
            setComActive(active);
            if (active) {
                comLayersGroup.addTo(map);
            } else {
                map.removeLayer(comLayersGroup);
            }
            if (intercomActive) {
                intercomLayersGroup.addTo(map);
            } else {
                map.removeLayer(intercomLayersGroup);
            }
        }
        if ('intercom' === filter) {
            setIntercomActive(active);
            if (active) {
                intercomLayersGroup.addTo(map);
            } else {
                map.removeLayer(intercomLayersGroup);
            }
            if (comActive) {
                comLayersGroup.addTo(map);
            } else {
                map.removeLayer(comLayersGroup);
            }
        }
    }

    useEffect(() => {
        // get asset on map by click but exclude double click to zoom
        let mapClicked = false;
        if (map) {
            map.on('click', function(e: any) {
                mapClicked = true;
                let lat = e.latlng.lat;
                let lng = e.latlng.lng;
                let rulerActive = false;
                const rulerElement = document.querySelector("div.leaflet-ruler");
                if (rulerElement) {
                    rulerActive = rulerElement.classList.contains('leaflet-ruler-clicked');
                }
                setTimeout(() => {
                    if (mapClicked && !rulerActive) {
                        getAssetByClick(lat, lng);
                        mapClicked = false;
                    }
                }, 1000);
            });
            map.on('dblclick', function() {
                mapClicked = false;
            });
        }
    }, [activeLayers]);

    return (
        <>
        <MapContainer
            {...zooms}
            {...rest}
            center={center}
            whenCreated={setMap}
        >
            {showHighlight && !highlightPoint && geojson &&
                <GeoJSON
                    key={highlightId}
                    data={geojson}
                    style={highlightLineStyle}
                />
            }
            {showHighlight && highlightPoint && highlightCircle &&
                <Marker
                    position={highlightPoint}
                    icon={circleIcon}
                />
            }
            {showHighlight && highlightPoint && !highlightCircle &&
                <Marker
                    position={highlightPoint}
                    icon={crossIcon}
                />
            }
            <Control position="topright">
                <div className="leaflet-bar">
                    <button onClick={() => goHome()}>
                        <SvgHome/>
                    </button>
                </div>
            </Control>
            <Control position="topright">
                <div className="leaflet-bar">
                    <button onClick={() => setShowLegend(!showLegend)}>
                        <SvgQuestion/>
                    </button>
                </div>
                <div className={`map-legend ${showLegend ? "show" : ""}`}>
                    <div className="box-shadow">
                        <span className="legend-title">{t('map_legend')}</span>
                        <ul className="list-group">
                            {activePatrimoineLayers.map((layerId: string) => {
                                const legendUrl = geoserverLegendUrl + layerId;
                                return <>
                                    <li className="list-group-item">
                                        <p className="legend-item">{t(`indices.patrimoine.${layerId.toLowerCase()}`)}</p>
                                        <img src={legendUrl} alt={layerId} />
                                    </li>
                                </>
                            })}
                            {
                                conformiteLayers &&
                                <li className="list-group-item">
                                    <p className="legend-item">{t('indices.conformite')}</p>
                                    <img src={LegendConform} alt={'conformite-legend'} height={'70px'}/>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </Control>
            {
                moFilter &&
                <Control position="topright">
                    <div className="leaflet-bar">
                        <button onClick={() => setShowFilterMo(!showFilterMo)}>
                            <img src={NetworkIcon} alt={"network icon"} height={30} />
                        </button>
                    </div>
                    <div className={`network-filter ${showFilterMo ? "show" : ""}`}>
                        <div className="box-shadow">
                            <span className="network-title">{t('mo')}</span>
                            <label className="leaflet-layerstree-header-label">
                                <input type="checkbox" className="leaflet-control-layers-selector" checked={comActive}/>
                                <span className="leaflet-control-layers-checkbox-toggle" onClick={() => onChangeNetworkFilter('com', !comActive)}> </span>
                                <span className="leaflet-layerstree-header-name">{t('com')}</span>
                            </label>
                            <label className="leaflet-layerstree-header-label">
                                <input type="checkbox" className="leaflet-control-layers-selector" checked={intercomActive}/>
                                <span className="leaflet-control-layers-checkbox-toggle" onClick={() => onChangeNetworkFilter('intercom', !intercomActive)}> </span>
                                <span className="leaflet-layerstree-header-name">{t('intercom')}</span>
                            </label>
                        </div>
                    </div>
                </Control>
            }
            <Control position="topright">
                <div className="leaflet-bar">
                    <button onClick={() => setLocateClicked(locateClicked+1)}>
                        <img src={MarkerIcon} alt={"marker icon"} height={30} />
                    </button>
                </div>
            </Control>
            <ScaleControl position={"bottomleft"} imperial={false} />
            <LocateComponent locateClicked={locateClicked} setLocateClicked={setLocateClicked}/>
            <GetBounds
                setLatLng={setLatLng}
                setBounds={setBounds}
                setBoundsNE={setBoundsNE}
                setBoundsSW={setBoundsSW}
                setZoom={setZoom}
                setMapInfo={setMapInfo}
            />
            <LeafletRuler />
            <ResizeMap setMapInfo={setMapInfo} mapInfo={mapInfo}/>
        </MapContainer>
    </>
    );
};

/**
 * <Suez3DViewer
 *     baseurl=""
 *     cesium-ion-access-token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxODJjYTU0Ny05MzdjLTRjZTgtOTA0OC1lNjUwZWFjMmRmZDkiLCJpZCI6MTc0ODc3LCJpYXQiOjE2OTg2NTc2NzN9.KUjyBvFocQJfgs1TYyjuSO5KAoo3pDl1JFMOWPRZOXI"
 *     globe-translucency-enabled
 * >
 * </Suez3DViewer>
 */

const mapStateToProps = (state: any) => {
  return {
    mapInfo: state.map,
    contract: state.contract.currentContract,
    search: state.search,
  };
};

const mapDispatchToProps = {
  setMapCoordinate: MapActions.setMapCoordinate,
  setMapInfo: MapActions.setMapInfo,
  showDetail: SearchActions.showDetail,
  showDetailControl: SearchActions.showDetailControl,
  getMapPreferences: UserActions.getMapPreferences,
  showReport: ReportActions.showReport,
  hideReport: ReportActions.hideReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeafletMapComponent);
