import cube from "../assets/img/icons/3D.svg";
import doc from "../assets/img/icons/doc.svg";
import file from "../assets/img/icons/file.svg";
import itv from "../assets/img/icons/itv.svg";
import pdf from "../assets/img/icons/pdf.svg";
import target from "../assets/img/icons/target.svg";

declare global {
    interface Window {
        REACT_APP_API_HOST: string;
        REACT_APP_APP_VERSION: string;
        REACT_APP_ANALYTICS_KEY: string;
        REACT_APP_FRESHDESK_HOST: string;
        REACT_APP_MAP_PORTAL_URL: string;
        REACT_APP_MAP_SERVICE_URL: string;
        REACT_APP_MAP_WEBMAP_ID: string;
        REACT_APP_MAP_WEBSCENE_ID: string;
        REACT_APP_MAPTROISD_ENABLED: string;
        REACT_APP_MAPTROISD_WEBMAP_ID: string;
        REACT_APP_MAPTROISD_WEBSCENE_ID: string;
        REACT_APP_SMARTSHAPE_HOST: string;
        REACT_APP_WEB_COMPONENT_HOST: string;
        REACT_APP_FUNCTIONALITY_ENABLED: string;
        REACT_APP_MEDIA_SERVER_HOST: string;
        REACT_APP_ITV_WFS_URL: string;
        REACT_APP_DEFAULT_LAT: number;
        REACT_APP_DEFAULT_LNG: number;
        REACT_APP_BBOX_SIDE_LENGTH: number;
        REACT_APP_DEFAULT_URL: string;
        REACT_APP_TOKEN_KEY: string;
        REACT_APP_GEOSERVER_HOST: string;
        REACT_APP_GEOSERVER_WMS_HOST: string;
        REACT_APP_PORTAL: string;
        REACT_APP_MATOMO_URL_BASE: string;
        REACT_APP_CESIUM_IFRAME_URL: string;
        REACT_APP_ENV: string;
    }
}

export const config = {
    MAP3D_ENABLED: window.REACT_APP_MAPTROISD_ENABLED || process.env.REACT_APP_MAPTROISD_ENABLED,
    FUNCTIONALITY_ENABLED: window.REACT_APP_FUNCTIONALITY_ENABLED || process.env.REACT_APP_FUNCTIONALITY_ENABLED,
    analyticsKey: window.REACT_APP_ANALYTICS_KEY || process.env.REACT_APP_ANALYTICS_KEY,
    apiUrl: window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST,
    freshdeskHost: window.REACT_APP_FRESHDESK_HOST || process.env.REACT_APP_FRESHDESK_HOST,
    host: window.location.origin,
    version: window.REACT_APP_APP_VERSION || process.env.REACT_APP_APP_VERSION,
    webComponentUrl: window.REACT_APP_WEB_COMPONENT_HOST || process.env.REACT_APP_WEB_COMPONENT_HOST,
    mediaServerHost: window.REACT_APP_MEDIA_SERVER_HOST || process.env.REACT_APP_MEDIA_SERVER_HOST,
    wfsBaseUrl: window.REACT_APP_ITV_WFS_URL || process.env.REACT_APP_ITV_WFS_URL,
    defaultUrl: window.REACT_APP_DEFAULT_URL || process.env.REACT_APP_DEFAULT_URL,
    tokenKey: window.REACT_APP_TOKEN_KEY || process.env.REACT_APP_TOKEN_KEY || '',
    geoserver: window.REACT_APP_GEOSERVER_HOST || process.env.REACT_APP_GEOSERVER_HOST,
    geoserverWms: window.REACT_APP_GEOSERVER_WMS_HOST || process.env.REACT_APP_GEOSERVER_WMS_HOST,
    portal: window.REACT_APP_PORTAL || process.env.REACT_APP_PORTAL,
    matomoUrlBase: window.REACT_APP_MATOMO_URL_BASE || process.env.REACT_APP_MATOMO_URL_BASE || 'https://matomo.jumeau-numerique-vision.fr/',
    environment: window.REACT_APP_ENV || process.env.REACT_APP_ENV || 'production',
};

export const mapConfig = {
    PORTAL_URL: window.REACT_APP_MAP_PORTAL_URL || process.env.REACT_APP_MAP_PORTAL_URL,
    SERVICE_URL: window.REACT_APP_MAP_SERVICE_URL || process.env.REACT_APP_MAP_SERVICE_URL,
    WEBMAP_ID: window.REACT_APP_MAP_WEBMAP_ID || process.env.REACT_APP_MAP_WEBMAP_ID, // 2d
    WEBSCENE_ID: window.REACT_APP_MAP_WEBSCENE_ID || process.env.REACT_APP_MAP_WEBSCENE_ID, // 3d
    DEFAULT_LAT: window.REACT_APP_DEFAULT_LAT || process.env.REACT_APP_DEFAULT_LAT,
    DEFAULT_LNG: window.REACT_APP_DEFAULT_LNG || process.env.REACT_APP_DEFAULT_LNG,
    BBOX_SIDE_LENGTH: window.REACT_APP_BBOX_SIDE_LENGTH || process.env.REACT_APP_BBOX_SIDE_LENGTH,
};

export const map3dConfig = {
    PORTAL_URL: window.REACT_APP_MAP_PORTAL_URL || process.env.REACT_APP_MAP_PORTAL_URL,
    SERVICE_URL: window.REACT_APP_MAP_SERVICE_URL || process.env.REACT_APP_MAP_SERVICE_URL,
    WEBMAP3D_ID: window.REACT_APP_MAPTROISD_WEBMAP_ID || process.env.REACT_APP_MAPTROISD_WEBMAP_ID,
    WEBSCENE3D_ID: window.REACT_APP_MAPTROISD_WEBSCENE_ID || process.env.REACT_APP_MAPTROISD_WEBSCENE_ID,
    CESIUM_IFRAME_URL: window.REACT_APP_CESIUM_IFRAME_URL || process.env.REACT_APP_CESIUM_IFRAME_URL,
};

export const smartshapeConfig = {
    host: window.REACT_APP_SMARTSHAPE_HOST || process.env.REACT_APP_SMARTSHAPE_HOST,
};

export const icons: any = {
    "icon-3D": cube,
    "icon-doc": doc,
    "icon-file": file,
    "icon-itv": itv,
    "icon-pdf": pdf,
    "icon-target": target,
};
