import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { categories } from "../../../config/constants";
import { ISearch } from "../../../store/redux/types";
import { getDocumentTitle } from "../../Detail/documents";
import Filters from "../../Filters";
import CountMessage from "../../Utils/messages/countMessage";
import NoResults from "../../Utils/messages/noResults";
import PdfViewer from "../../Utils/pdfViewer";

interface IDocuments {
  result: any;
  search?: ISearch;
  downloadUrl: any;
}
function Documents(props: IDocuments) {

  const { t } = useTranslation();
  const { search, result, downloadUrl } = props;
  const [titlePDF, setTitlePDF] = useState("");
  const [uriPDF, setUriPDF] = useState("");
  const [documentLength, setDocumentLength] = useState(0);
  const [PDFViewerIsActive, setPDFViewerIsActive] = useState(false);
  const [activeDoc, setActiveDoc] = useState(-1);

  const isPDF = (uri: string, mimeType: string): boolean => {
    return (
      !!downloadUrl(uri) &&
      mimeType === "application/pdf"
    );
  };

  const showPDFViewer = (document: any, index: number) => {
    setTitlePDF(getDocumentTitle(document));
    setUriPDF(downloadUrl(document["dt:downloadUrl"]));
    setPDFViewerIsActive(!PDFViewerIsActive);
    setDocumentLength(document["cmis:contentStreamLength"]);
    ReactGA.event({ action: `Pdf viewer: ${getDocumentTitle(document)}`, category: "PDF" });
    setActiveDoc(index);
  };

  return (
    <>
      {PDFViewerIsActive && (
        <PdfViewer
          title={titlePDF}
          uri={uriPDF}
          visible={PDFViewerIsActive}
          handleClosePdfViewer={() => setPDFViewerIsActive(!PDFViewerIsActive)}
          documentLength={documentLength ? documentLength : 0}
        />
      )}
      <Row className="category-tab gaia-search__main-tab-result">
        {search && result.data.facets && (
          <Col sm={5} className="no-padding category-filters">
            <Filters facets={result.data.facets} filters={result.data.filters} searchCategory={categories.DOC} />
          </Col>
        )}
        <Col sm={result.data.facets ? 7 : 12} className="no-padding category-tab">
          {search && (
            <CountMessage
              maxResult={search.maxResult}
              nbResult={result.data.documents.length}
              total={result.data.totalCount}
            />
          )}
          <ul className="gaia-search-data no-padding">
            {result.data.documents.length > 0 &&
              result.data.documents.map((document: any, index: number) => {
                return (
                  <li
                    className={`gaia-search-data__item ${index === activeDoc ? "active" : ""}`}
                    key={index}
                  >
                    <span className="gaia-search-data__item-content">{getDocumentTitle(document)}</span>
                    <div className="gaia-search-data__item-links">
                      {isPDF(document["gaia:downloadUrl"], document["cmis:contentStreamMimeType"]) && (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 100, hide: 200 }}
                          overlay={
                            <Tooltip id="tooltip">
                              {t("visualize_pdf")}
                            </Tooltip>
                          }
                        >
                          <i
                            className="gaia-search-data__item-links-icon material-icons clickable"
                            onClick={() => showPDFViewer(document, index)}
                          >
                            remove_red_eye
                          </i>
                        </OverlayTrigger>
                      )}
                      <a
                        href={downloadUrl(document["dt:downloadUrl"])}
                        onClick={() => { setActiveDoc(index); }}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 100, hide: 200 }}
                          overlay={
                            <Tooltip id="tooltip">
                              {t("download_document")}
                            </Tooltip>
                          }
                        >
                          <i className="gaia-search-data__item-links-icon material-icons" >file_download</i>
                        </OverlayTrigger>
                      </a>
                      <a
                        href={document["dt:previewUrl"]}
                        onClick={() => { setActiveDoc(index); }}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 100, hide: 200 }}
                          overlay={
                            <Tooltip id="tooltip">
                              {t("open_in_ged")}
                            </Tooltip>
                          }
                        >
                          <i className="gaia-search-data__item-links-icon material-icons">open_in_new</i>
                        </OverlayTrigger>
                      </a>
                    </div>
                  </li>
                );
              })}
            {result.data.documents.length === 0 && (
              <>
                <li>
                  <NoResults />
                </li>
              </>
            )}
          </ul>
        </Col>
      </Row>
    </>
  );
}
const mapStateToProps = (state: any, props: IDocuments) => {
  return { search: state.search, ...props };
};

export default connect(mapStateToProps)(Documents);
