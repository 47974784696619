import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReportFilters from "../../components/Filters/Report/ReportFilters";
import Map from "../../components/Map";
import NetworkTable from "../../components/NetworkTable/NetworkTable";
import Smartshape from "../../components/Smartshape";
import { highlightObjects } from "../../manager/map/loader";
import { zoomToCoordinates } from "../../manager/map/navigation";
import {MapActions, ReportActions, SearchActions, UserActions} from "../../store/redux/actions";
import {
  IParcel,
  IPlu,
  IProject,
  IReport,
  IReportColumn,
  IReportData,
  IReportFilter,
  ISearch,
  ISignal,
  ISiteIndustriel,
  IUser,
} from "../../store/redux/types";
import "./report.scss";
import {Contract} from "../../types";
import {Search as SearchService} from "../../services/Search";
import {categories} from "../../config/constants";
import {SEARCH} from "../../store/redux/constants";
import {launchLoading} from "../../store/redux/actions/search";
import {History} from "history";

export enum reportTabs {
  list = "list",
  map = "map",
}

export interface IReportLayersData {
  conform: IReport[];
  notConformOther: IReport[];
  EUEPNotConform: IReport[];
  EPEUNotConform: IReport[];
  EUEPEPEUNotConform: IReport[];
  functioningNotConform: IReport[];
  unknown: IReport[];
}

export interface IProjectLayersData {
  confirmed: IProject[];
  suspecte: IProject[];
  travaux: IProject[];
}

export interface ISignalLayersData {
  free: ISignal[];
  harmful: ISignal[];
  noise: ISignal[];
  odour: ISignal[];
  overflow: ISignal[];
  rejection: ISignal[];
  road: ISignal[];
  work: ISignal[];
}

export interface ISiteIndustrielLayersData {
  siteIndustrielConform: ISiteIndustriel[];
  siteIndustrielNotConform: ISiteIndustriel[];
  siteIndustrielNotConcerned: ISiteIndustriel[];
  siteIndustrielUnknown: ISiteIndustriel[];
}

export interface IParcelLayersData {
  disconnected: IParcel[];
  regulated: IParcel[];
  unknown: IParcel[];
}

export interface IPluLayersData {
  favorable: IPlu[];
  defavorable: IPlu[];
  inexistant: IPlu[];
}

export interface IBVParcelLayersData {
  lessthan1: IParcel[];
  between1and5: IParcel[];
  greaterThan5: IParcel[];
}

interface IReportProps {
  connection: any;
  shownDetailItem: any;
  currentTab: keyof typeof reportTabs;
  changeTab: (newTab: keyof typeof reportTabs) => void;
  reportMapPosition: [number, number, number];
  filters: IReportFilter;
  geoserverBVs: any;
  geoserverBVsColors: string[];
  getMapPreferences: () => void;
  getReports: (contract: string, filters: IReportFilter, sortField: string, sortDirection: string, currentPage?: number) => any;
  getProjects: (filters: any, sortField: string, sortDirection: string, currentPage?: number) => any;
  getSiteIndustriels: (filters: any, sortField: string, sortDirection: string, currentPage?: number) => any;
  getParcels: (filters: any, sortField: string, sortDirection: string, currentPage?: number) => any;
  getPlu: (filters: any, sortField: string, sortDirection: string, currentPage?: number) => any;
  getGeoserverBV: (colors: string[]) => any;
  listType: string;
  reports: IReport[];
  setReportMapPosition: (position: [number, number, number]) => void;
  showDetail: (itemID: string) => any;
  showProjectDetail: (projectId: string) => any;
  showSignalDetail: (signalId: string) => any;
  showSiteIndustrielDetail: (siteIndustrielId: string) => any;
  sortDirection: string;
  sortField: string;
  setSort: (sortField: string) => void;
  projectSortDirection: string;
  projectSortField: string;
  setProjectSort: (sortField: string) => void;
  signalSortDirection: string;
  signalSortField: string;
  setSignalSort: (sortField: string) => void;
  siteIndustrielSortDirection: string;
  siteIndustrielSortField: string;
  setSiteIndustrielSort: (sortField: string) => void;
  parcelSortDirection: string;
  parcelSortField: string;
  pluSortDirection: string;
  pluSortField: string;
  showSmartshape: boolean;
  isMapDisplayedFullscreen: boolean;
  contract: Contract;
  showDetailControl: any;
  history: History;
  setMapInfo: (payload: any) => void;
  search?: ISearch;
  defaultControlTableHeader: any;
}

export const Report = (props: IReportProps) => {
  const elementRef: any = useRef();

  const [currentTab, setCurrentTab]: [keyof typeof reportTabs, (t: keyof typeof reportTabs) => void]
    = useState(props.currentTab);

  const { t } = useTranslation();
  const {
    reportMapPosition,
    history,
    setMapInfo,
    search,
    showDetailControl
  } = props;

  const conformityState = [
    "Conforme",
    "Non conforme",
    "Non Conforme sans Dysfonctionnement",
    "A Déterminer",
  ];

  // useEffect(() => {
  //   props.getGeoserverBV(props.geoserverBVsColors);
  // }, []);

  // useEffect(() => {
  //   if (props.listType === "parcel" || props.listType === "plu") {
  //     setCurrentTab(reportTabs.map);
  //   }
  // }, [props.listType]);

  // useEffect(() => {
  //   if ("undefined" !== typeof props.getReports && currentTab === reportTabs.map) {
  //     props.getReports(props.contract.id, props.filters, 'createdAt', props.sortDirection);
  //     // props.getReports(props.contract.id, props.filters, props.sortField, props.sortDirection);
  //   }
  // }, [props.filters, props.sortField, props.sortDirection]);

  const calculateCentroid = (data: any) => {
    let longitude: number = 0;
    let latitude: number = 0;
    longitude = (parseFloat(data[1][1]) + parseFloat(data[0][1])) / 2;
    latitude = (parseFloat(data[1][0]) + parseFloat(data[0][0])) / 2;
    return { longitude, latitude };
  };

  // useEffect(() => {
  //   if (props.reports.length === 1 && props.reports[0].position && currentTab === reportTabs.map) {
  //     const coordinates = calculateCentroid(props.reports[0].position);
  //     zoomToCoordinates(coordinates.latitude, coordinates.longitude, 250);
  //   }
  // }, [props.reports]);

  // useEffect(() => {
  //   if (props.currentTab === reportTabs.map) {
  //     setTimeout(() => {
  //       // console.log(props);
  //       if (props.reportMapPosition) {
  //         zoomToCoordinates(props.reportMapPosition[0], props.reportMapPosition[1], props.reportMapPosition[2]);
  //       }
  //     }, 2000);
  //   }
  // }, [props.currentTab]);

  const [shownItemId, setShownItemId]: [number | undefined, (id: number | undefined) => void] = useState();

  const handleIdPdcClick = (idPdc: any): void => {
    SearchService.getControlObject(idPdc)
        .then((responseData: any) => {
          const adressePDC = responseData.adressePDC;
          const highlight = {
            show: true,
            type: 'conformity',
            id: idPdc,
            point: [adressePDC.longitude, adressePDC.latitude],
          }
          setMapInfo({
            center: {
              longitude: adressePDC.latitude,
              latitude: adressePDC.longitude
            },
            extent: null,
            highlight: highlight,
            autoZoom: true,
          });
          props.history.push("/");
        });
  };

  // const handleProjectNameClick = (projectID: any): void => {
  //   props.showProjectDetail(projectID)
  //     .then((data: any) => {
  //       if (data.latitude !== 0 && data.longitude !== 0) {
  //         props.setReportMapPosition([data.latitude, data.longitude, 250]);
  //       }
  //       setShownItemId(projectID);
  //       setCurrentTab(reportTabs.map);
  //     });
  // };

  // const handleSignalCategoryClick = (signalID: any): void => {
  //   props.showSignalDetail(signalID)
  //     .then((data: any) => {
  //       if (data.address.latitude !== 0 && data.address.longitude !== 0) {
  //         props.setReportMapPosition([data.address.latitude as number, data.address.longitude as number, 250]);
  //       }
  //       setShownItemId(signalID);
  //       setCurrentTab(reportTabs.map);
  //     });
  // };

  // const handleSiteIndustrielNameClick = (siteIndustrielId: any): void => {
  //   props.showSiteIndustrielDetail(siteIndustrielId)
  //     .then((data: any) => {
  //       if (data.centroid !== "") {
  //         const position: number[] = data.centroid.split(",");
  //         props.setReportMapPosition([position[1] as number, position[0] as number, 1400]);
  //       }
  //       setShownItemId(siteIndustrielId);
  //       setCurrentTab(reportTabs.map);
  //     });
  // };

  useEffect(() => {
    props.changeTab(currentTab);
    props.getMapPreferences();
  }, [currentTab]);

  const prefixLabelFormatter = (cell: string, row: IReport, index: number, colName: string) => {
    return i18next.t(`report:${colName}.${cell}`);
  };

  const conformiteFormatter = (value: any) => {
    if (value == undefined) {
      return "";
    } else {
      return i18next.t(`report:conformity.${value}`);
    }
  };

  const majFormatter = (value: string) => {
    return value ? value.toUpperCase(): '';
  };

  const ecaTypeCodeFormatter = (value: string) => {
    return i18next.t(`report:conformityCheckType.${value}`);
  };

  const listeAssetFormatter = (liste: any) => {
    if (liste !== undefined) {
      let display = '';
      liste.map((equipement: any) => {
        let type = equipement.typeReseau !== undefined ? equipement.typeReseau : equipement.equipementType;
        display = display + equipement.typeAsset + ' - ' + type + "\n\r";
      });
      return display;
    }
    return '';
  };

  // const siteIndustrielConformiteFormatter = (cell: string) => {
  //   return i18next.t(`report:siteIndustriel.conformity.${cell}`);
  // };

  // const isClickable = (networkID: string, row: IReport): boolean => {
  //   return !("Neuf" === networkID || "" === row.iD);
  // };

  const changeTab = (tabName: any) => {
    if (Object.values(reportTabs).includes(tabName)) {
      setCurrentTab(tabName);
      if (shownItemId && tabName === reportTabs.list) {
        const elements = document.getElementsByClassName("selected");
        for (const element of elements as any) {
          element.classList.remove("selected");
        }
        document.getElementsByClassName(`row-${shownItemId}`)[0].classList.add("selected");
        document.getElementsByClassName(`row-${shownItemId}`)[0].scrollIntoView({
          behavior: "auto",
          block: "end",
        });
      }
      ReactGA.event({ action: `Change tab: ${tabName}`, category: "Report" });
    }
  };

  const idPdcFormatter = (idPdc: string, row: IReport) => {
      return (
        <span
          className={`report-table__idPdc clickable row-${idPdc}`}
          onClick={() => {
            handleIdPdcClick(idPdc);
          }}
        >
          {idPdc}
        </span>
      );
  };

  // const projectNameFormatter = (name: string, row: IProject) => {
  //   return (
  //     <span
  //       className={`report-table__networkID clickable row-${row.id}`}
  //       onClick={() => {
  //         handleProjectNameClick(row.id);
  //       }}
  //     >
  //       {name}
  //     </span>
  //   );
  // };

  // const signalCategoryFormatter = (category: string, row: IProject) => {
  //   return (
  //     <span
  //       className={`report-table__networkID clickable row-${row.id}`}
  //       onClick={() => {
  //         handleSignalCategoryClick(row.id);
  //       }}
  //     >
  //       {i18next.t(`report:category.${category}`)}
  //     </span>
  //   );
  // };

  // const siteIndustrielNameFormatter = (name: string, row: ISiteIndustriel) => {
  //   return (
  //     <span
  //       className={`report-table__networkID clickable row-${row.idCD92}`}
  //       onClick={() => {
  //         handleSiteIndustrielNameClick(row.idCD92);
  //       }}
  //     >
  //       {name}
  //     </span>
  //   );
  // };

  // const siteIndustrielConnectionNumberFormatter = (name: any, row: any) => {
  //   const content = row.connectionNumber.map((connection: any) => {
  //   //index:name
  //     return (
  //       <span
  //         className={`report-table__networkID clickable row-${connection.layer_asset_id}`}
  //         onClick={() => {
  //           handleNetworkNumberClick(connection.layer_asset_id);
  //         }}
  //       >
  //         {connection.nomexplo}<br></br>
  //       </span>
  //     );
  //   });
  //   return (content);
  // };

  const sortByColumn = (e: any, column: any) => {
    e.stopPropagation();
    const sortField = column.dataField;
    props.setSort(sortField);
  };

  const sortProjectByColumn = (e: any, column: any) => {
    e.stopPropagation();
    const sortField = column.dataField;
    props.setProjectSort(sortField);
  };

  const sortSignalByColumn = (e: any, column: any) => {
    e.stopPropagation();
    const sortField = column.dataField;
    props.setSignalSort(sortField);
  };

  const sortSiteIndustrielByColumn = (e: any, column: any) => {
    e.stopPropagation();
    const sortField = column.dataField;
    props.setSiteIndustrielSort(sortField);
  };

  const tableSortFunc = (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => {
    if (order === "asc") {
      return b - a;
    }
    return a - b; // desc
  };

  const defaultControlTableHeader = [
    {
      dataField: "data.infoPDC.idPDC",
      formatter: idPdcFormatter,
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 0,
      sort: false,
      text: "report:control.numberPdc",
      weight: 0,
    },
    {
      dataField: "data.infoPDC.typePDC",
      formatter: majFormatter,
      key: 1,
      sort: false,
      text: "report:control.typePDC",
      weight: 1,
    },
    {
      dataField: "data.listeAsset",
      formatter: listeAssetFormatter,
      key: 2,
      sort: false,
      text: "report:control.equipement",
      weight: 2,
    },
    {
      dataField: "data.infoPDC.adressePDC.adresseComplete",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 1,
      sort: false,
      text: "report:control.address",
      weight: 1,
    },
    {
      dataField: "data.infoPDC.adressePDC.commune",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 2,
      sort: false,
      text: "report:control.city",
      weight: 2,
    },
    {
      dataField: "data.infoPDC.propertyType",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 3,
      sort: false,
      text: "report:control.propertyType",
      weight: 3,
    },
    {
      dataField: "ecaTypeCode",
      formatter: ecaTypeCodeFormatter,
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 4,
      sort: false,
      text: "report:control.ecaTypeCode",
      weight: 4,
    },
    {
      dataField: "data.conformitePDC.conformite",
      formatter: conformiteFormatter,
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 4,
      sort: false,
      text: "report:control.conformite",
      weight: 4,
    },
    {
      dataField: "data.donneeAdmin.numITV",
      // formatExtraData: "conformityCheckType",
      // formatter: prefixLabelFormatter,
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 6,
      sort: false,
      text: "report:control.numITV",
      weight: 6,
    },
    {
      dataField: "data.donneeAdmin.datePrevue",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 7,
      sort: false,
      text: "report:control.datePrevue",
      weight: 7,
    },
    {
      dataField: "data.donneeAdmin.dateITV",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 8,
      sort: false,
      text: "report:control.dateITV",
      weight: 8,
    },
    {
      dataField: "currentStatus.status.label",
      // dataField: "data.infoPDC.statut",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 9,
      sort: false,
      text: "report:control.statut",
      weight: 9,
    },
    {
      dataField: "infoParcelle",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 10,
      sort: false,
      text: "report:control.infoParcelle",
      weight: 10,
    },
  ];

  // const defaultProjectTableHeader = [
  //   {
  //     dataField: "name",
  //     formatter: projectNameFormatter,
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 0,
  //     sort: true,
  //     text: "report:project.name",
  //     weight: 0,
  //   },
  //   {
  //     dataField: "status",
  //     formatExtraData: "status",
  //     formatter: prefixLabelFormatter,
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 1,
  //     sort: true,
  //     text: "report:project.status",
  //     weight: 1,
  //   },
  //   {
  //     dataField: "address",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 2,
  //     sort: true,
  //     text: "report:project.address",
  //     weight: 2,
  //   },
  //   {
  //     dataField: "city",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 3,
  //     sort: true,
  //     text: "report:project.city",
  //     weight: 3,
  //   },
  //   {
  //     dataField: "pcDate",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 4,
  //     sort: true,
  //     text: "report:project.pcDate",
  //     weight: 4,
  //   },
  //   {
  //     dataField: "pcNumber",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 5,
  //     sort: true,
  //     text: "report:project.pcNumber",
  //     weight: 5,
  //   },
  //   {
  //     dataField: "hold",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 6,
  //     sort: true,
  //     text: "report:project.hold",
  //     weight: 6,
  //   },
  //   {
  //     dataField: "zac",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 7,
  //     sort: true,
  //     text: "report:project.zac",
  //     weight: 7,
  //   },
  //   {
  //     dataField: "actor",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 8,
  //     sort: true,
  //     text: "report:project.actor",
  //     weight: 8,
  //   },
  //   {
  //     dataField: "deliveryDate",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 9,
  //     sort: true,
  //     text: "report:project.deliveryDate",
  //     weight: 9,
  //   },
  //   {
  //     dataField: "hasFiles",
  //     key: 10,
  //     sort: false,
  //     text: "report:project.hasFiles",
  //     weight: 10,
  //   },
  //   {
  //     dataField: "url",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortProjectByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 7,
  //     sort: true,
  //     text: "report:project.url",
  //     weight: 7,
  //   },
  // ];
  //
  // const defaultSignalTableHeader = [
  //   {
  //     dataField: "category",
  //     formatExtraData: "category",
  //     // formatter: prefixLabelFormatter,
  //     formatter: signalCategoryFormatter,
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSignalByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 0,
  //     sort: true,
  //     text: "report:signal.category",
  //     weight: 0,
  //   },
  //   {
  //     dataField: "status",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSignalByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 1,
  //     sort: true,
  //     text: "report:signal.status",
  //     weight: 1,
  //   },
  //   {
  //     dataField: "detail",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSignalByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 2,
  //     sort: true,
  //     text: "report:signal.detail",
  //     weight: 2,
  //   },
  //   {
  //     dataField: "city",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSignalByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 3,
  //     sort: true,
  //     text: "report:signal.city",
  //     weight: 3,
  //   },
  //   {
  //     dataField: "address",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSignalByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 4,
  //     sort: true,
  //     text: "report:signal.address",
  //     weight: 4,
  //   },
  //   {
  //     dataField: "date",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSignalByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 5,
  //     sort: true,
  //     text: "report:signal.date",
  //     weight: 5,
  //   },
  //   {
  //     dataField: "origin",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSignalByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 6,
  //     sort: false,
  //     text: "report:signal.origin",
  //     weight: 6,
  //   },
  //   {
  //     dataField: "hasComments",
  //     key: 7,
  //     sort: false,
  //     text: "report:signal.origin",
  //     weight: 7,
  //   },
  //   {
  //     dataField: "hasFiles",
  //     key: 8,
  //     sort: false,
  //     text: "report:signal.hasFiles",
  //     weight: 8,
  //   },
  // ];
  //
  // const defaultSiteIndustrielTableHeader = [
  //   {
  //     dataField: "name",
  //     formatter: siteIndustrielNameFormatter,
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 0,
  //     sort: true,
  //     text: "report:siteIndustriel.name",
  //     weight: 0,
  //   },
  //   {
  //     dataField: "idCD92",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 1,
  //     sort: true,
  //     text: "report:siteIndustriel.idCD92",
  //     weight: 1,
  //   },
  //   {
  //     dataField: "siteIndustrielConformity",
  //     formatter: siteIndustrielConformiteFormatter,
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 2,
  //     sort: true,
  //     text: "report:siteIndustriel.conformity.label",
  //     weight: 2,
  //   },
  //   {
  //     dataField: "address",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 3,
  //     sort: true,
  //     text: "report:siteIndustriel.address",
  //     weight: 3,
  //   },
  //   {
  //     dataField: "updatedAt",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 4,
  //     sort: true,
  //     text: "report:siteIndustriel.updatedAt",
  //     weight: 4,
  //   },
  //   {
  //     dataField: "city",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 5,
  //     sort: true,
  //     text: "report:siteIndustriel.city",
  //     weight: 5,
  //   },
  //   {
  //     dataField: "connectionNumber",
  //     formatter: siteIndustrielConnectionNumberFormatter,
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 6,
  //     sort: true,
  //     text: "report:siteIndustriel.connectionNumber",
  //     weight: 6,
  //   },
  //   {
  //     dataField: "domain",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 7,
  //     sort: true,
  //     text: "report:siteIndustriel.domain",
  //     weight: 7,
  //   },
  //   {
  //     dataField: "samplingPoint",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 8,
  //     sort: true,
  //     text: "report:siteIndustriel.samplingPoint",
  //     weight: 8,
  //   },
  //   {
  //     dataField: "samplingPointCity",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 9,
  //     sort: true,
  //     text: "report:siteIndustriel.samplingPointCity",
  //     weight: 9,
  //   },
  //   {
  //     dataField: "visitDate",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 10,
  //     sort: true,
  //     text: "report:siteIndustriel.visitDate",
  //     weight: 10,
  //   },
  //   {
  //     dataField: "sampleType",
  //     headerEvents: {
  //       onClick: (e: any, column: any) => {
  //         sortSiteIndustrielByColumn(e, column);
  //       },
  //     },
  //     sortFunc: tableSortFunc,
  //     key: 11,
  //     sort: true,
  //     text: "report:siteIndustriel.sampleType",
  //     weight: 11,
  //   },
  // ];

  const translatedControlTableHeader = defaultControlTableHeader.map((column: IReportColumn) => {
    column.text = t(column.text);
    return column;
  });

  // const translatedProjectTableHeader = defaultProjectTableHeader.map((column: IReportColumn) => {
  //   column.text = t(column.text);
  //   return column;
  // });
  //
  // const translatedSignalTableHeader = defaultSignalTableHeader.map((column: IReportColumn) => {
  //   column.text = t(column.text);
  //   return column;
  // });
  //
  // const translatedSiteIndustrielTableHeader = defaultSiteIndustrielTableHeader.map((column: IReportColumn) => {
  //   column.text = t(column.text);
  //   return column;
  // });

  const [controlTableHeader, setControlTableHeader] = useState(translatedControlTableHeader);
  // const [projectTableHeader, setProjectableHeader] = useState(translatedProjectTableHeader);
  // const [signalTableHeader, setSignalTableHeader] = useState(translatedSignalTableHeader);
  // const [siteIndustrielTableHeader, setSiteIndustrielTableHeader] = useState(translatedSiteIndustrielTableHeader);

  const renderReportTable = (listType: string) => {
    const switchButton = document.getElementById("switch-btn") as HTMLInputElement;
    if (switchButton) {
      if (reportTabs.list === currentTab) {
        switchButton.className = "esri-component esri-widget--button esri-widget esri-interactive hidden";
      } else {
        switchButton.className = "esri-component esri-widget--button esri-widget esri-interactive";
      }
    }
    switch (listType) {
      // case "project":
      //   return (
      //     <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
      //       <NetworkTable
      //         controlTableHeader={controlTableHeader}
      //         projectTableHeader={projectTableHeader}
      //         signalTableHeader={signalTableHeader}
      //         siteIndustrielTableHeader={siteIndustrielTableHeader}
      //         projectSortField={props.projectSortField}
      //         sortDirection={props.projectSortDirection}
      //       />
      //     </div>
      //   );
      // case "signal":
      //   return (
      //     <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
      //       <NetworkTable
      //         controlTableHeader={controlTableHeader}
      //         projectTableHeader={projectTableHeader}
      //         signalTableHeader={signalTableHeader}
      //         siteIndustrielTableHeader={siteIndustrielTableHeader}
      //         signalSortField={props.signalSortField}
      //         sortDirection={props.signalSortDirection}
      //       />
      //     </div>
      //   );
      case "control":
        return (
          <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
            <NetworkTable
              controlTableHeader={controlTableHeader}
              // projectTableHeader={projectTableHeader}
              // signalTableHeader={signalTableHeader}
              // siteIndustrielTableHeader={siteIndustrielTableHeader}
              sortField={props.sortField}
              sortDirection={props.sortDirection}
            />
          </div>
        );
      // case "siteIndustriel":
      //   return (
      //     <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
      //       <NetworkTable
      //         controlTableHeader={controlTableHeader}
      //         projectTableHeader={projectTableHeader}
      //         signalTableHeader={signalTableHeader}
      //         siteIndustrielTableHeader={siteIndustrielTableHeader}
      //         sortField={props.siteIndustrielSortField}
      //         sortDirection={props.siteIndustrielSortDirection}
      //       />
      //     </div>
      //   );
      default:
        return (
          <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
            <NetworkTable
              controlTableHeader={controlTableHeader}
              // projectTableHeader={projectTableHeader}
              // signalTableHeader={signalTableHeader}
              // siteIndustrielTableHeader={siteIndustrielTableHeader}
              sortField={props.sortField}
              sortDirection={props.sortDirection}
            />
          </div>
        );
    }
  };

  return (
    <div className={"report"} ref={elementRef}>
      <div className={"report--menu_container"}>
        <ReportFilters
          controlTableHeader={controlTableHeader}
          updateControlWeight={setControlTableHeader}
          // projectTableHeader={projectTableHeader}
          // updateProjectWeight={setProjectableHeader}
          // signalTableHeader={signalTableHeader}
          // updateSiteIndustrielWeight={setSiteIndustrielTableHeader}
          // siteIndustrielTableHeader={siteIndustrielTableHeader}
          // updateSignalWeight={setSignalTableHeader}
          currentLocalTab={currentTab}
          changeLocalTab={setCurrentTab}
        />
      </div>
      <div className={"report--body"}>
        <div className={"report--body_container"}>
          {renderReportTable(props.listType)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  report: IReportData,
  user: IUser,
  search: ISearch,
  contract: { currentContract: Contract },
  map: any
}) => {
  return {
    currentTab: state.report.currentTab,
    filters: state.report.filters,
    geoserverBVs: state.report.geoserverBVs,
    geoserverBVsColors: state.report.geoserverBVsColors,
    isMapDisplayedFullscreen: state.search.isMapDisplayedFullscreen,
    listType: state.user.listType,
    projectSortDirection: state.report.projectSortField,
    projectSortField: state.report.projectSortField,
    reportMapPosition: state.user.reportMapPosition,
    reports: state.report.reports,
    signalSortDirection: state.report.signalSortDirection,
    signalSortField: state.report.signalSortField,
    showSmartshape: state.report.showSmartshape,
    shownDetailItem: state.report.shownDetailItem,
    sortDirection: state.report.sortDirection,
    sortField: state.report.sortField,
    contract: state.contract.currentContract,
    showDetailControl: SearchActions.showDetailControl,
    mapInfo: state.map,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions, ...MapActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
