import { mdiArrowLeftBoldCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React, { ReactElement, useEffect, useState } from "react";
import { Breadcrumb, Collapse } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reportTabs } from "../../../pages/Report";
import { ReportActions } from "../../../store/redux/actions";
import { IReportData, IUser } from "../../../store/redux/types";
import DetailContainer from "../../Detail/detailContainer";
import ProjectDetailContainer from "../../Detail/Project/projectDetailContainer";
import SignalDetailContainer from "../../Detail/Signal/signalDetailContainer";
import SiteIndustrielDetailContainer from "../../Detail/SiteIndustriel/siteIndustrielDetailContainer";
import CollapseToggle from "../../Utils/buttons/CollapseToggle";
import ControlListFilters from "./ControlListFilters";
import ParcelListFilters from "./ParcelListFilters";
import PluListFilters from "./PluListFilters";
import ProjectListFilters from "./ProjectListFilters";
import "./ReportFilters.scss";
import SignalListFilters from "./SignalListFilters";
import SiteIndustrielListFilters from "./SiteIndustrielListFilters";

interface IReportFilters {
  currentLocalTab?: keyof typeof reportTabs;
  changeLocalTab?: (t: keyof typeof reportTabs) => void;
  listType: string;
  controlTableHeader: Array<{ weight: number, text: string }>;
  updateControlWeight: any;
  projectTableHeader?: Array<{ weight: number, text: string }>;
  updateProjectWeight?: any;
  signalTableHeader?: Array<{ weight: number, text: string }>;
  updateSignalWeight?: any;
  siteIndustrielTableHeader?: Array<{ weight: number, text: string }>;
  updateSiteIndustrielWeight?: any;
  shownItemDetails?: any;
  showDetails?: boolean;
  shownProjectItemDetails?: any;
  showProjectDetails?: boolean;
  shownSignalItemDetails?: any;
  showSignalDetails?: boolean;
  shownSiteIndustrielItemDetails?: any;
  showSiteIndustrielDetails?: boolean;
  hideDetail?: () => void;
  hideProjectDetail?: () => void;
  hideSignalDetail?: () => void;
  hideSiteIndustrielDetail?: () => void;
}

const ReportFilters = (props: IReportFilters): ReactElement => {

  const { t } = useTranslation();
  const { shownItemDetails,
    showDetails,
    hideDetail,
    shownProjectItemDetails,
    showProjectDetails,
    shownSignalItemDetails,
    showSignalDetails,
    shownSiteIndustrielItemDetails,
    showSiteIndustrielDetails,
    hideProjectDetail,
    hideSignalDetail,
    hideSiteIndustrielDetail,
    listType,
  } = props;
  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    setIsHidden(false);
  }, [props.shownItemDetails]);
  const changeTab = (tabName: any) => {
    if (Object.values(reportTabs).includes(tabName)) {
      ReactGA.event({ action: `Change tab: ${tabName}`, category: "Report" });
    }
  };

  const handleBreadcrumbClick = (destination: keyof typeof reportTabs): void => {
    if ("undefined" !== typeof hideDetail) {
      hideDetail();
    }
    if ("undefined" !== typeof hideProjectDetail) {
      hideProjectDetail();
    }
    if ("undefined" !== typeof hideSignalDetail) {
      hideSignalDetail();
    }
    if ("undefined" !== typeof hideSiteIndustrielDetail) {
      hideSiteIndustrielDetail();
    }
    if (reportTabs.list === destination) {
      changeTab(reportTabs.list);
      if (props.listType === "control") {
        if (shownItemDetails["asset:layer_asset_id"]) {
          const shownItemId = shownItemDetails["asset:layer_asset_id"];
          setTimeout(() => {
            document.getElementsByClassName(`row-${shownItemId}`)[0].classList.add("selected");
            document.getElementsByClassName(`row-${shownItemId}`)[0].scrollIntoView({
              behavior: "auto",
              block: "end",
            });
          }, 2000);
        }
      } else if (props.listType === "siteIndustriel") {
        if (shownProjectItemDetails.ID_CD92) {
          const shownItemId = shownProjectItemDetails.ID_CD92;
          setTimeout(() => {
            document.getElementsByClassName(`row-${shownItemId}`)[0].classList.add("selected");
            document.getElementsByClassName(`row-${shownItemId}`)[0].scrollIntoView({
              behavior: "auto",
              block: "end",
            });
          }, 2000);
        }
      } else if (props.listType === "project") {
        if (shownProjectItemDetails.id) {
          const shownItemId = shownProjectItemDetails.id;
          setTimeout(() => {
            document.getElementsByClassName(`row-${shownItemId}`)[0].classList.add("selected");
            document.getElementsByClassName(`row-${shownItemId}`)[0].scrollIntoView({
              behavior: "auto",
              block: "end",
            });
          }, 2000);
        }
      } else {
        if (shownProjectItemDetails.id) {
          const shownItemId = shownSignalItemDetails.id;
          setTimeout(() => {
            document.getElementsByClassName(`row-${shownItemId}`)[0].classList.add("selected");
            document.getElementsByClassName(`row-${shownItemId}`)[0].scrollIntoView({
              behavior: "auto",
              block: "end",
            });
          }, 2000);
        }
      }
    }
  };
  const getBreadcrump = (objectCategory: string, objectTitle: string) => {
    return (<Breadcrumb className={"gaia_breadcrumbs"}>
      <Breadcrumb.Item
        className={"gaia_breadcrumb search_keywords clickable"}
        onClick={() => handleBreadcrumbClick(reportTabs.list)}
      >
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 200 }}
          overlay={
            <Tooltip id="tooltip">
              {t("back_to_filters")}
            </Tooltip>
          }
        >
          <Icon path={mdiArrowLeftBoldCircleOutline} size={1} />
        </OverlayTrigger>
      </Breadcrumb.Item>
      <Breadcrumb.Item
        className={"gaia_breadcrumb search_keywords clickable"}
        onClick={() => handleBreadcrumbClick(reportTabs.list)}
      >
        {objectCategory}
      </Breadcrumb.Item>
      <Breadcrumb.Item className={"gaia_breadcrumb estate_object_name"}>
        {objectTitle}
      </Breadcrumb.Item>
    </Breadcrumb>);
  };

  return (
    <>
      <div className={"report--menu--tabs"}>
        {isHidden && props.listType === "control" && (
          <div className="report--menu_collapsed--body">
            <span>{t("report:controlReport")}</span>
          </div>
        )}
        {isHidden && props.listType === "project" && (
          <div className="report--menu_collapsed--body">
            <span>{t("report:projectsReport")}</span>
          </div>
        )}
        {isHidden && props.listType === "signal" && (
          <div className="report--menu_collapsed--body">
            <span>{t("report:signalsReport")}</span>
          </div>
        )}
        {isHidden && props.listType === "siteIndustriel" && (
          <div className="report--menu_collapsed--body">
            <span>{t("report:siteIndustrielsReport")}</span>
          </div>
        )}
        {isHidden && props.listType === "parcel" && (
          <div className="report--menu_collapsed--body">
            <span>{t("report:parcelReport")}</span>
          </div>
        )}
        {isHidden && props.listType === "plu" && (
          <div className="report--menu_collapsed--body">
            <span>{t("report:pluReport")}</span>
          </div>
        )}
        <Collapse in={!isHidden} timeout={0} dimension="width">
          <div className={"report--menu-body"}>
            {showDetails &&
              shownItemDetails &&
              shownItemDetails.constructor === Object &&
              Object.keys(shownItemDetails).length !== 0 && (
                <div className={"report--menu-body--details-container"}>
                {getBreadcrump(props.listType === "control" ? t("report:controlReport") : t("report:siteIndustrielsReport"), `${t(shownItemDetails["index:name"])} ${shownItemDetails["asset:id_apic"]}`)}
                  <DetailContainer shownItem={props.shownItemDetails}/>
                </div>
              )}
            {showProjectDetails &&
              shownProjectItemDetails &&
              shownProjectItemDetails.constructor === Object &&
              Object.keys(shownProjectItemDetails).length !== 0 && (
                <div className={"report--menu-body--details-container"}>
                {getBreadcrump(t("report:projectsReport"), shownProjectItemDetails.name)}
                  <ProjectDetailContainer shownItem={props.shownProjectItemDetails} />
                </div>
              )}
            {showSignalDetails &&
              shownSignalItemDetails &&
              shownSignalItemDetails.constructor === Object &&
              Object.keys(shownSignalItemDetails).length !== 0 && (
                <div className={"report--menu-body--details-container"}>
                {getBreadcrump(t("report:signalsReport"), shownSignalItemDetails.address.address)}
                  <SignalDetailContainer shownItem={props.shownSignalItemDetails} />
                </div>
              )}
            {showSiteIndustrielDetails &&
              shownSiteIndustrielItemDetails &&
              shownSiteIndustrielItemDetails.constructor === Object &&
              Object.keys(shownSiteIndustrielItemDetails).length !== 0 && (
                <div className={"report--menu-body--details-container"}>
                {getBreadcrump(t("report:siteIndustrielsReport"), shownSiteIndustrielItemDetails.NomEtab)}
                  <SiteIndustrielDetailContainer shownItem={props.shownSiteIndustrielItemDetails} />
                </div>
              )}
            {!showDetails &&
              !showProjectDetails &&
              "control" === listType && (
                <ControlListFilters tableHeader={props.controlTableHeader} updateWeight={props.updateControlWeight} />
              )}
            {!showDetails &&
              !showProjectDetails &&
              "project" === listType && (
                <ProjectListFilters tableHeader={props.projectTableHeader} updateWeight={props.updateProjectWeight} />
              )}
            {!showDetails &&
              !showSignalDetails &&
              "signal" === listType && (
                <SignalListFilters tableHeader={props.signalTableHeader} updateWeight={props.updateSignalWeight} />
              )}
            {!showDetails &&
              !showSiteIndustrielDetails &&
              "siteIndustriel" === listType && (
              <SiteIndustrielListFilters
                tableHeader={props.siteIndustrielTableHeader}
                updateWeight={props.updateSiteIndustrielWeight}
              />
              )}
            {!showDetails && "parcel" === listType && (<ParcelListFilters/>)}
            {!showDetails && "plu" === listType && (<PluListFilters/>)}
          </div>
        </Collapse>
      </div>
      <CollapseToggle toggled={isHidden} handler={setIsHidden} showClose={false} show={true}/>
    </>
  );
};

const mapStateToProps = (state: { report: IReportData, user: IUser }) => {
  return {
    showDetails: state.report.showDetails,
    shownItemDetails: state.report.shownDetailItem,
    showProjectDetails: state.report.showProjectDetails,
    shownProjectItemDetails: state.report.shownProjectDetailItem,
    showSignalDetails: state.report.showSignalDetails,
    showSiteIndustrielDetails: state.report.showSiteIndustrielDetails,
    shownSignalItemDetails: state.report.shownSignalDetailItem,
    shownSiteIndustrielItemDetails: state.report.shownSiteIndustrielDetailItem,
    listType: state.user.listType,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(ReportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportFilters);
