import { currentContract } from "../actions";
import Request from './Request';
import { ApiUrls, Constants } from '../constants';
import { AssetLayer, ContractHeimdall, Contract, PostContractRequest, SiteConfiguration } from "../types";
import { store } from "../store";

export class ContractsService {
    public static setCurrentContract(contract: Contract) {
        store.dispatch(currentContract(contract));
    }

    public static setContractDetail(contract: Contract) {
        contract.label = contract.siteConfiguration.title;
        contract.assetMapping = JSON.parse(contract.assetMapping);
        contract.assetLayers = [].concat(contract.assetLayers)
            .sort((a: AssetLayer, b: AssetLayer) => a.order > b.order ? 1 : -1);
        store.dispatch(currentContract(contract));
    }

    public static getPermissionsContracts(code: string, currentPage?: number, itemsPerPage?: number) {
        const body = {
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.DEFAULT_PER_PAGE
        }

        return Request.getWithParams(ApiUrls.PERMISSIONS_ADMIN, body, false, true)
            .then(response => response.data)
            .then(response => response[code]);
    }

    public static getContractsInfo() {
        return Request.get(ApiUrls.CONTRACT_INFO_URL, false, true)
            .then(response => response.data);
    }

    public static getContracts(currentPage?: number, itemsPerPage?: number) {
        const body = {
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.NOTIFICATIONS_PER_PAGE
        }

        return Request.getWithParams(ApiUrls.CONTRACTS, body, false, true)
            .then(response => response);
    }

    public static postContract(request: PostContractRequest) {
        return Request.post(ApiUrls.CONTRACT, request)
            .then(response => response.status)
    }

    public static getContractById(contractId: string) {
        const endpoint = ApiUrls.CONTRACT_BY_ID.replace(':contractId', contractId);
        return Request.getWithParams(endpoint, {}, false, true)
            .then(response => response.data)
    }

    public static getContractFromFirstPermission(permissions: any[]) {
        const contract: ContractHeimdall = permissions[0].contract;
        return ContractsService.getContractById(contract.id);
    }

    public static getSiteConfiguration(contractId: string) {
        const url = ApiUrls.SITE_CONFIGURATION.replace(":contractId", contractId);
        return Request.get(url, false, true);
    }

    public static createSiteConfiguration(siteConfiguration: SiteConfiguration) {
        const url = ApiUrls.SITE_CONFIGURATION.replace(":contractId", siteConfiguration.contract);
        return Request.post(url, siteConfiguration)
            .then(response => response.data);
    }

    public static updateSiteConfiguration(siteConfiguration: SiteConfiguration) {
        const url = ApiUrls.SITE_CONFIGURATION.replace(":contractId", siteConfiguration.contract);
        return Request.put(url, siteConfiguration)
            .then(response => response.data);
    }

    public static getContractsByUser() {
        return Request.get(ApiUrls.CONTRACTS_BY_USER, false, true);
    }
}
