import React, {useEffect, useRef, useState, createRef} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Detail from "../../components/Detail";
import LeafletMapComponent from "../../components/Map/leaflet";
import Map from "../../components/Map";
import Search from "../../components/Search";
import Smartshape from "../../components/Smartshape";
import {MapActions, ReportActions, SearchActions, UserActions} from "../../store/redux/actions";
import {IReport, IReportColumn, IReportFilter, IUser} from "../../store/redux/types";
import Loading from "../Loading";
import { LeafletMapConfig } from "../../manager/map/config";
import "./home.scss";
import ReportFilters from "../../components/Filters/Report/ReportFilters";
import {useTranslation} from "react-i18next";
import NetworkTable from "../../components/NetworkTable/NetworkTable";
import {Search as SearchService} from "../../services/Search";
import i18next from "i18next";
import {SzButton} from "@suezenv/react-theme-components";
import {map3dConfig} from "../../config";

interface IHomeProps {
  user: IUser;
  geoserverBVs: any;
  geoserverBVsColors: string[];
  showResultDetail: boolean;
  showSearchResult: boolean;
  showSmartshape: boolean;
  reportDisplayed: boolean;
  shownDetailItem: any;
  isDisplayed: boolean;
  isMapDisplayedFullscreen: boolean;
  getGeoserverBV: (colors: string[]) => any;
  getSigObject: (itemID: string) => any;
  homeMapPosition: [number, number, number];
  setHomeMapPosition: (coordinates: [number, number, number]) => void;
  reports: IReport;
  reportFilters: IReportFilter;
  reportSortDirection: string;
  reportSortField: string;
  mapEsri: boolean;
  mapLeaflet: boolean;
  contract: any;
  listType: any;
  currentTab: any;
  sortDirection: string;
  sortField: string;
  mapInfo: any;
  setMapInfo: (payload: any) => void;
  hideDetail?: () => void;
  hideReport: () => void;
  showReport: () => void;
  closeAll: () => void;
  openDetailObject: () => void;
  openDetailTab: (tab: any) => void;
}

const filters = {
  address: "",
  city: [],
  controlType: [],
  conformity: [],
  veConformity: [],
  vptConformity: [],
  conformityCheckType: [],
  connectionNumber: "",
  currentState: [],
  emOperatingSector: [],
  endDate: null,
  generatedMailEndDate: null,
  generatedMailStartDate: null,
  majorFaultType: [],
  networkOperatingSector: [],
  propertyType: [],
  startDate: null,
};

const zooms = {
    zoom: LeafletMapConfig.ZOOM_DEFAULT,
    minZoom: LeafletMapConfig.ZOOM_MIN,
    maxZoom: LeafletMapConfig.ZOOM_MAX,
    maxNativeZoom: LeafletMapConfig.MAX_NATIVE_ZOOM,
};

export const Home = (props: IHomeProps) => {
  const {
    contract,
    user,
    showSearchResult,
    showResultDetail,
    mapEsri,
    isDisplayed,
    showSmartshape,
    homeMapPosition,
    getSigObject,
    setHomeMapPosition,
    reports,
    reportSortDirection,
    reportSortField,
    geoserverBVs,
    mapLeaflet,
    isMapDisplayedFullscreen,
    shownDetailItem,
    listType,
    sortField,
    sortDirection,
    mapInfo,
    setMapInfo,
    reportDisplayed,
    hideReport,
    openDetailObject,
    openDetailTab,
  } = props;

  const {t} = useTranslation();

  const [collapseReportTable, setCollapseReportTable] = useState(false);
  const active3d = contract.currentContract.contractMetadatas?.active3d === "1";
  const [show3dCesium, setShow3dCesium] = useState(false);
  let mapLeafletClass = "map-leaflet ";
  let cesiumClass = "iframe-cesium-container ";
  let btn3dClass = "btn-3d ";
  if (show3dCesium) {
    mapLeafletClass = mapLeafletClass + 'map-hidden';
    btn3dClass = btn3dClass + "active";
  } else {
    cesiumClass = cesiumClass + "map-hidden";
  }

  const {lat, lng} = contract.currentContract.siteConfiguration.mapInfo;

  const reportActive = contract.currentContract.contractMetadatas?.report == '1';

  const center = {
    lat: lat as number || LeafletMapConfig.DEFAULT_LAT as number,
    lng: lng as number || LeafletMapConfig.DEFAULT_LNG as number
  };

  const cesiumIframeUrl = map3dConfig.CESIUM_IFRAME_URL || '';

  const zoomToHeight = (zoom: number): number => {
    const defaultHeight = 5000;
    const index = zoom.toString();
    let zoomHeight : { [key: string]: number } = {
      '6': 2400000,
      '7': 1200000,
      '8': 600000,
      '9': 300000,
      '10': 160000,
      '11': 80000,
      '12': 40000,
      '13': 20000,
      '14': 10000,
      '15': 5000,
      '16': 2500,
      '17': 1500,
      '18': 750,
      '19': 500,
      '20': 350,
    };
    if (undefined !== zoomHeight[index]) {
      return zoomHeight[index];
    }
    return defaultHeight;
  }

  const fly3dToPosition = (mapInfo: any): void => {
    const iframe = document.getElementById("cesium") as HTMLIFrameElement;
    if (iframe.contentWindow) {
      if (iframe.contentWindow) {
        let currentCenter = mapInfo.center;
        const height = zoomToHeight(mapInfo.zoom);
        if (currentCenter.lat && currentCenter.lng) {
          iframe.contentWindow.postMessage(
              JSON.stringify({
                "event": "getCallToIframe",
                "lat": currentCenter.lat,
                "lng": currentCenter.lng,
                "height": height
              }),
              cesiumIframeUrl,
          );

          console.log('messager from jumeau to iframe')
        }
      }
    }
    window.addEventListener('message', function(event) {
      console.log(event.origin)
      if (event.origin === "http://localhost:1234") {
        console.log('messager from iframe to jumeau')
        let message = JSON.parse(event.data);
        console.log(message)
      }
    });
  }

  useEffect(() => {
    setCollapseReportTable(false);
  }, [ reportDisplayed ]);

  const closeReportHandler = (): void => {
    hideReport();
    setCollapseReportTable(true);
  }

  const handleIdPdcClick = (idPdc: any): void => {
    hideReport();
    SearchService.getControlObject(idPdc)
        .then((responseData: any) => {
          const adressePDC = responseData.adressePDC;
          const highlight = {
            show: true,
            type: 'conformity',
            id: idPdc,
            point: [adressePDC.longitude, adressePDC.latitude],
          }
          setMapInfo({
            center: {
              longitude: adressePDC.latitude,
              latitude: adressePDC.longitude
            },
            extent: null,
            highlight: highlight,
            autoZoom: true,
          });
          setTimeout(() => {
            openDetailObject();
          }, 300);
        });
  };

  const idPdcFormatter = (idPdc: string, row: IReport) => {
    return (
        <span
            className={`report-table__idPdc clickable row-${idPdc}`}
            onClick={() => {
              handleIdPdcClick(idPdc);
              openDetailTab('synthesis');
            }}
        >
          {idPdc}
        </span>
    );
  };

  const majFormatter = (value: string) => {
    return value ? value.toUpperCase() : '';
  };

  const listeAssetFormatter = (liste: any, data: any) => {
    if (liste !== undefined) {
      let display = '';
      if (liste && liste.length > 1 ) {
        liste.map((equipement: any) => {
          let type = equipement.typeReseau !== undefined ? equipement.typeReseau : equipement.equipementType;
          display = display + t(`asset:history.asset.${equipement.typeAsset}`) + ' - ' + t(`asset:history.asset.${type}`) + "\n\r";
        });
      }

      return <span className={`report-table__listAsset clickable`}
          onClick={() => {
            handleIdPdcClick(data.data.infoPDC.idPDC);
            openDetailTab('history');
          }}
      >
        {display}
      </span>;
    }
    return '';
  };

  const ecaTypeCodeFormatter = (value: string) => {
    return i18next.t(`report:conformityCheckType.${value}`);
  };

  const conformiteFormatter = (value: any, data: any) => {
    if (value == undefined) {
      return "";
    } else {
      return <span className={`report-table__conformite clickable`}
          onClick={() => {
            handleIdPdcClick(data.data.infoPDC.idPDC);
            openDetailTab('history');
          }}
        >
          {i18next.t(`report:conformity.${value}`)}
        </span>;
    }
  };

  const defaultControlTableHeader = [
    {
      dataField: "data.infoPDC.idPDC",
      formatter: idPdcFormatter,
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 0,
      sort: false,
      text: "report:control.numberPdc",
      weight: 0,
    },
    {
      dataField: "data.infoPDC.typePDC",
      formatter: majFormatter,
      key: 1,
      sort: false,
      text: "report:control.typePDC",
      weight: 1,
    },
    {
      dataField: "data.listeAsset",
      formatter: listeAssetFormatter,
      key: 2,
      sort: false,
      text: "report:control.equipement",
      weight: 2,
    },
    {
      dataField: "data.infoPDC.adressePDC.adresseComplete",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 1,
      sort: false,
      text: "report:control.address",
      weight: 1,
    },
    {
      dataField: "data.infoPDC.adressePDC.commune",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 2,
      sort: false,
      text: "report:control.city",
      weight: 2,
    },
    {
      dataField: "data.infoPDC.propertyType",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 3,
      sort: false,
      text: "report:control.propertyType",
      weight: 3,
    },
    {
      dataField: "ecaTypeCode",
      formatter: ecaTypeCodeFormatter,
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 4,
      sort: false,
      text: "report:control.ecaTypeCode",
      weight: 4,
    },
    {
      dataField: "data.conformitePDC.conformite",
      formatter: conformiteFormatter,
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 4,
      sort: false,
      text: "report:control.conformite",
      weight: 4,
    },
    {
      dataField: "data.donneeAdmin.numITV",
      // formatExtraData: "conformityCheckType",
      // formatter: prefixLabelFormatter,
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 6,
      sort: false,
      text: "report:control.numITV",
      weight: 6,
    },
    {
      dataField: "data.donneeAdmin.datePrevue",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 7,
      sort: false,
      text: "report:control.datePrevue",
      weight: 7,
    },
    {
      dataField: "data.donneeAdmin.dateITV",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 8,
      sort: false,
      text: "report:control.dateITV",
      weight: 8,
    },
    {
      dataField: "currentStatus.status.label",
      // dataField: "data.infoPDC.statut",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 9,
      sort: false,
      text: "report:control.statut",
      weight: 9,
    },
    {
      dataField: "infoParcelle",
      // headerEvents: {
      //   onClick: (e: any, column: any) => {
      //     sortByColumn(e, column);
      //   },
      // },
      // sortFunc: tableSortFunc,
      key: 10,
      sort: false,
      text: "report:control.infoParcelle",
      weight: 10,
    },
  ];

  const translatedControlTableHeader = defaultControlTableHeader.map((column: IReportColumn) => {
    column.text = t(column.text);
    return column;
  });
  const [controlTableHeader, setControlTableHeader] = useState(translatedControlTableHeader);

  const renderReportTable = (listType: string) => {
    const switchButton = document.getElementById("switch-btn") as HTMLInputElement;
    if (switchButton) {
      switchButton.className = "hidden";
    }
    switch (listType) {
      case "control":
        return (
            <div className={`report--body_table_container`}>
              <NetworkTable
                  controlTableHeader={controlTableHeader}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  isHidden={collapseReportTable}
                  setIsHidden={setCollapseReportTable}
                  showClose={true}
                  show={reportDisplayed}
                  closeHandler={closeReportHandler}
              />
            </div>
        );
      default:
        return (
            <div className={`report--body_table_container}`}>
              <NetworkTable
                  controlTableHeader={controlTableHeader}
                  sortField={sortField}
                  sortDirection={sortDirection}
              />
            </div>
        );
    }
  };

  if (!user.logged) {
    return (
        <div className="map">
          <Loading/>
        </div>
    );
  } else {
    return (
        <>
          {showSearchResult && (
              <div className="no-padding search-result-container">
                <Search/>
              </div>
          )}
          {showResultDetail && (
              <div className="no-padding search-detail-container">
                <Detail/>
              </div>
          )}
          {reportActive &&
            <div className={`report-container ${collapseReportTable || !reportDisplayed ? "report-container-hidden" : ""}`}>
              {reportDisplayed && (
                <div className={"report--menu_container"}>
                  <ReportFilters
                      controlTableHeader={controlTableHeader}
                      updateControlWeight={setControlTableHeader}
                  />
                </div>
              )}
              <div className={"report--body"}>
                <div className={"report--body_container"}>
                  {renderReportTable(listType)}
                </div>
              </div>
            </div>
          }
          <div className="display-container">
            {mapEsri &&
                <div className={`no-padding map-esri ${isDisplayed && showSmartshape ? "hidden" : ""}`}>
                  <Map
                      defaultCoordinates={homeMapPosition}
                      showDetailObject={getSigObject}
                      setMapPosition={setHomeMapPosition}
                      filters={filters}
                      reports={reports}
                      sortDirection={reportSortDirection}
                      sortField={reportSortField}
                      geoserverBVs={geoserverBVs}
                      displayProjectLayers={false}
                      displaySignalLayers={false}
                      displaySiteIndustrielLayers={false}
                      displayParcelLayers={false}
                      displayPluLayers={false}
                      showSmartshape={showSmartshape}
                  />
                </div>
            }
            {mapLeaflet &&
                <>
                  {active3d &&
                      <>
                        <SzButton
                            type="button"
                            className={btn3dClass}
                            variant="secondary"
                            onClick={()=>{
                              setShow3dCesium(!show3dCesium)
                              setTimeout(() => {
                                fly3dToPosition(mapInfo);
                              }, 100)
                            }}
                        >
                          <i className="material-icons">
                            3d_rotation
                          </i>
                        </SzButton>
                      </>
                  }
                  <div className={cesiumClass}>
                    <iframe
                        id="cesium"
                        title="cesium 3d viewer"
                        width="100%"
                        src={cesiumIframeUrl}
                        className={"iframe"}
                    >
                    </iframe>
                  </div>
                  <div className={mapLeafletClass}>
                    <LeafletMapComponent
                        zooms={zooms}
                        center={center}
                    />
                  </div>
                </>
            }
            {showSmartshape && (
                <div className={`no-padding smartshape ${isMapDisplayedFullscreen ? "hidden" : ""}`}>
                  <Smartshape shownDetailItem={shownDetailItem}/>
                </div>
            )}
          </div>
        </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    geoserverBVs: state.report.geoserverBVs,
    geoserverBVsColors: state.report.geoserverBVsColors,
    homeMapPosition: state.user.homeMapPosition,
    isDisplayed: state.search.isDisplayed,
    isMapDisplayedFullscreen: state.search.isMapDisplayedFullscreen,
    reportFilters: state.report.filters,
    reportSortDirection: state.report.sortDirection,
    reportSortField: state.report.sortField,
    reports: state.report.reports,
    showResultDetail: state.search.flag.showResultDetail,
    shownDetailItem: state.search.detail.shownDetailItem,
    showSearchResult: state.search.flag.showSearchResult,
    showSmartshape: state.search.flag.showSmartshape,
    contract: state.contract,
    user: state.user,
    mapEsri: false,
    mapLeaflet: true,
    currentTab: state.report.currentTab,
    filters: state.report.filters,
    listType: state.user.listType,
    projectSortDirection: state.report.projectSortField,
    projectSortField: state.report.projectSortField,
    reportMapPosition: state.user.reportMapPosition,
    signalSortDirection: state.report.signalSortDirection,
    signalSortField: state.report.signalSortField,
    sortDirection: state.report.sortDirection,
    sortField: state.report.sortField,
    setMapInfo: MapActions.setMapInfo,
    reportDisplayed: state.report.reportDisplayed,
    showReport: ReportActions.showReport,
    hideReport: ReportActions.hideReport,
    closeAll: SearchActions.closeAll,
    openDetailObject: SearchActions.openDetailObject,
    openDetailTab: SearchActions.openDetailTab,
    mapInfo: state.map,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...SearchActions, ...UserActions, ...ReportActions, ...MapActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
