import ReactDOMServer from 'react-dom/server';
import { ReactComponent as PompagA } from "../../../assets/img/leaflet/A-POMPAG-A.svg";
import { ReactComponent as PompagEP } from "../../../assets/img/leaflet/A-POMPAG-EP.svg";
import { ReactComponent as PompagEU } from "../../../assets/img/leaflet/A-POMPAG-EU.svg";
import { ReactComponent as PompagU } from "../../../assets/img/leaflet/A-POMPAG-U.svg";
import { ReactComponent as RegardA } from "../../../assets/img/leaflet/A-REGARD-A.svg";
import { ReactComponent as RegardEP } from "../../../assets/img/leaflet/A-REGARD-EP.svg";
import { ReactComponent as RegardEU } from "../../../assets/img/leaflet/A-REGARD-EU.svg";
import { ReactComponent as RegardU } from "../../../assets/img/leaflet/A-REGARD-U.svg";
import { ReactComponent as RegbraA } from "../../../assets/img/leaflet/A-REGBRA-A.svg";
import { ReactComponent as RegbraEP } from "../../../assets/img/leaflet/A-REGBRA-EP.svg";
import { ReactComponent as RegbraEU } from "../../../assets/img/leaflet/A-REGBRA-EU.svg";
import { ReactComponent as RegbraU } from "../../../assets/img/leaflet/A-REGBRA-U.svg";
import { ReactComponent as RaccorA } from "../../../assets/img/leaflet/A-RACCOR-A.svg";
import { ReactComponent as RaccorEP } from "../../../assets/img/leaflet/A-RACCOR-EP.svg";
import { ReactComponent as RaccorEU } from "../../../assets/img/leaflet/A-RACCOR-EU.svg";
import { ReactComponent as RaccorU } from "../../../assets/img/leaflet/A-RACCOR-U.svg";
import { ReactComponent as DeversA } from "../../../assets/img/leaflet/A-DEVERS-A.svg";
import { ReactComponent as DeversEP } from "../../../assets/img/leaflet/A-DEVERS-EP.svg";
import { ReactComponent as DeversEU } from "../../../assets/img/leaflet/A-DEVERS-EU.svg";
import { ReactComponent as DeversU } from "../../../assets/img/leaflet/A-DEVERS-U.svg";
import { ReactComponent as TraphyA } from "../../../assets/img/leaflet/A-TRAPHY-A.svg";
import { ReactComponent as TraphyEP } from "../../../assets/img/leaflet/A-TRAPHY-EP.svg";
import { ReactComponent as TraphyEU } from "../../../assets/img/leaflet/A-TRAPHY-EU.svg";
import { ReactComponent as TraphyU } from "../../../assets/img/leaflet/A-TRAPHY-U.svg";
import { ReactComponent as CondebA } from "../../../assets/img/leaflet/A-CONDEB-A.svg";
import { ReactComponent as CondebEP } from "../../../assets/img/leaflet/A-CONDEB-EP.svg";
import { ReactComponent as CondebEU } from "../../../assets/img/leaflet/A-CONDEB-EU.svg";
import { ReactComponent as CondebU } from "../../../assets/img/leaflet/A-CONDEB-U.svg";
import { ReactComponent as CapteuA } from "../../../assets/img/leaflet/A-CAPTEU-A.svg";
import { ReactComponent as CapteuEP } from "../../../assets/img/leaflet/A-CAPTEU-EP.svg";
import { ReactComponent as CapteuEU } from "../../../assets/img/leaflet/A-CAPTEU-EU.svg";
import { ReactComponent as CapteuU } from "../../../assets/img/leaflet/A-CAPTEU-U.svg";
import { ReactComponent as ChasseA } from "../../../assets/img/leaflet/A-CHASSE-A.svg";
import { ReactComponent as ChasseEP } from "../../../assets/img/leaflet/A-CHASSE-EP.svg";
import { ReactComponent as ChasseEU } from "../../../assets/img/leaflet/A-CHASSE-EU.svg";
import { ReactComponent as ChasseU } from "../../../assets/img/leaflet/A-CHASSE-U.svg";
import { ReactComponent as StepA } from "../../../assets/img/leaflet/A-STEP-A.svg";
import { ReactComponent as StepEP } from "../../../assets/img/leaflet/A-STEP-EP.svg";
import { ReactComponent as StepEU } from "../../../assets/img/leaflet/A-STEP-EU.svg";
import { ReactComponent as StepU } from "../../../assets/img/leaflet/A-STEP-U.svg";
import { ReactComponent as VentouA } from "../../../assets/img/leaflet/A-VENTOU-A.svg";
import { ReactComponent as VentouEP } from "../../../assets/img/leaflet/A-VENTOU-EP.svg";
import { ReactComponent as VentouEU } from "../../../assets/img/leaflet/A-VENTOU-EU.svg";
import { ReactComponent as VentouU } from "../../../assets/img/leaflet/A-VENTOU-U.svg";
import { ReactComponent as BoucheA } from "../../../assets/img/leaflet/A-BOUCHE-A.svg";
import { ReactComponent as BoucheEP } from "../../../assets/img/leaflet/A-BOUCHE-EP.svg";
import { ReactComponent as BoucheEU } from "../../../assets/img/leaflet/A-BOUCHE-EU.svg";
import { ReactComponent as BoucheU } from "../../../assets/img/leaflet/A-BOUCHE-U.svg";
import { ReactComponent as BassinA } from "../../../assets/img/leaflet/A-BASSIN-A.svg";
import { ReactComponent as BassinEP } from "../../../assets/img/leaflet/A-BASSIN-EP.svg";
import { ReactComponent as BassinEU } from "../../../assets/img/leaflet/A-BASSIN-EU.svg";
import { ReactComponent as BassinU } from "../../../assets/img/leaflet/A-BASSIN-U.svg";
import { ReactComponent as PltA } from "../../../assets/img/leaflet/T-PLT-A.svg";
import L from "leaflet";
import React from "react";

export const wfsIcons: any = {
    'A_POMPAG_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<PompagEU/>),
            className: "a_pompag_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<PompagEP/>),
            className: "a_pompag_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<PompagU/>),
            className: "a_pompag_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<PompagA/>),
            className: "a_pompag_point_a"
        }),
    },
    'A_REGARD_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<RegardEU/>),
            className: "a_regard_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<RegardEP/>),
            className: "a_regard_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<RegardU/>),
            className: "a_regard_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<RegardA/>),
            className: "a_regard_point_a"
        }),
    },
    'A_REGBRA_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<RegbraEU/>),
            className: "a_regbra_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<RegbraEP/>),
            className: "a_regbra_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<RegbraU/>),
            className: "a_regbra_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<RegbraA/>),
            className: "a_regbra_point_a"
        }),
    },
    'A_DEVERS_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<DeversEU/>),
            className: "a_devers_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<DeversEP/>),
            className: "a_devers_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<DeversU/>),
            className: "a_devers_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<DeversA/>),
            className: "a_devers_point_a"
        }),
    },
    'A_RACCOR_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<RaccorEU/>),
            className: "a_raccor_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<RaccorEP/>),
            className: "a_raccor_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<RaccorU/>),
            className: "a_raccor_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<RaccorA/>),
            className: "a_raccor_point_a"
        }),
    },
    'A_TRAPHY_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<TraphyEU/>),
            className: "a_traphy_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<TraphyEP/>),
            className: "a_traphy_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<TraphyU/>),
            className: "a_traphy_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<TraphyA/>),
            className: "a_traphy_point_a"
        }),
    },
    'A_CONDEB_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<CondebEU/>),
            className: "a_condeb_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<CondebEP/>),
            className: "a_condeb_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<CondebU/>),
            className: "a_condeb_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<CondebA/>),
            className: "a_condeb_point_a"
        }),
    },
    'A_CAPTEU_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<CapteuEU/>),
            className: "a_capteu_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<CapteuEP/>),
            className: "a_capteu_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<CapteuU/>),
            className: "a_capteu_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<CapteuA/>),
            className: "a_capteu_point_a"
        }),
    },
    'A_CHASSE_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<ChasseEU/>),
            className: "a_chasse_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<ChasseEP/>),
            className: "a_chasse_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<ChasseU/>),
            className: "a_chasse_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<ChasseA/>),
            className: "a_chasse_point_a"
        }),
    },
    'A_STEP_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<StepEU/>),
            className: "a_step_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<StepEP/>),
            className: "a_step_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<StepU/>),
            className: "a_step_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<StepA/>),
            className: "a_step_point_a"
        }),
    },
    'A_VENTOU_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<VentouEU/>),
            className: "a_ventou_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<VentouEP/>),
            className: "a_ventou_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<VentouU/>),
            className: "a_ventou_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<VentouA/>),
            className: "a_ventou_point_a"
        }),
    },
    'A_BOUCHE_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<BoucheEU/>),
            className: "a_bouche_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<BoucheEP/>),
            className: "a_bouche_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<BoucheU/>),
            className: "a_bouche_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<BoucheA/>),
            className: "a_bouche_point_a"
        }),
    },
    'A_BASSIN_point' : {
        'EU' : L.divIcon({
            html: ReactDOMServer.renderToString(<BassinEU/>),
            className: "a_bassin_point_eu"
        }),
        'EP' : L.divIcon({
            html: ReactDOMServer.renderToString(<BassinEP/>),
            className: "a_bassin_point_ep"
        }),
        'UN' : L.divIcon({
            html: ReactDOMServer.renderToString(<BassinU/>),
            className: "a_bassin_point_u"
        }),
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<BassinA/>),
            className: "a_bassin_point_a"
        }),
    },
    'T_PLT_point' : {
        'A' : L.divIcon({
            html: ReactDOMServer.renderToString(<PltA/>),
            className: "t_plt_point_a"
        }),
    },
};