import classnames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { SzSideMenu } from "@suezenv/react-theme-components";
import { config } from "../../config";
import { RightsHelper } from "../../helper/RightsHelper";
import {IError, IReport, IUser} from "../../store/redux/types";
import { Roles, RoleAdminPortal } from "../../constants";
import Header from "../Header";
import Loading from "../Utils/loading";
import ErrorAuthenticationMessage from "../Utils/messages/errorAuthenticationMessage";
import ErrorMessage from "../Utils/messages/errorMessage";
import "./container.scss";
import {Contract} from "../../types";
import {bindActionCreators} from "redux";
import {ReportActions} from "../../store/redux/actions";

interface IContainerProps {
  connected: boolean;
  children: ReactNode;
  error: IError;
  showSearchResult: boolean;
  showSmartshape: boolean;
  loading: boolean;
  user: IUser;
  currentContract: Contract;
  showReport: () => void;
  hideReport: () => void;
  hideDetail: () => void;
  report: any;
}

export enum menuItems {
  home = "home",
  dashboard = "dashboard",
  indicator = "indicator",
  mapping = "mapping",
  report = "report",
  history = "history",
  favorite = "favorite",
  profil = "profil",
  contract = "contract",
  territoriale = "territoriale",
}

function Container(Props: IContainerProps) {
  if (config.analyticsKey) {
    ReactGA.initialize(config.analyticsKey);
  }
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentTab, changeTab] = useState(menuItems.home);
  const { children, connected, error, loading, user, currentContract,
    showReport, hideReport, hideDetail, report} = Props;
  const asideClasses = classnames("gaia-aside float-left  sz-aside-exit-done", {
    connected,
  });
  const containerClasses = classnames("vision-container", {
    connected,
    isOpen: connected && isOpen,
  });

  const reportIconClass = classnames("report-icon", {
    active: report.reportDisplayed && "/" == window.location.pathname
  });

  const activeClassName = classnames("active", {
    report_active: report.reportDisplayed && "/" == window.location.pathname
  });

  useEffect(() => {
    switch (window.location.pathname) {
      case "/dashboard":
        changeTab(menuItems.dashboard);
        break;
      case "/history":
        changeTab(menuItems.history);
        break;
      case "/favorite":
        changeTab(menuItems.favorite);
        break;
      case "/report":
        changeTab(menuItems.report);
        break;
      case "/indicator":
        changeTab(menuItems.indicator);
        break;
      case "/admin/contract":
        changeTab(menuItems.contract);
        break;
      case "/gestion-territoriale":
        changeTab(menuItems.territoriale);
        break;
      case "/":
      default:
        changeTab(menuItems.home);
        break;
    }
  }, [window.location.pathname]);

  let menu = [
    {
      className: "material-icons",
      icon: "search",
      link: "/",
      name: menuItems.mapping,
      title: t(menuItems.mapping),
      roles: [Roles.ROLE_USER],
      onClick: () => hideReport(),
    }];

  if (currentContract.contractMetadatas?.report == '1') {
    menu = menu.concat(
        [
            {
              className: reportIconClass,
              icon: " ",
              link: "/",
              name: menuItems.report,
              title: t(menuItems.report),
              roles: [Roles.ROLE_USER],
              onClick: () => showReport(),
            }
        ]);
  }

  menu = menu.concat(
    [{
      className: "material-icons",
      icon: "history",
      link: "/history",
      name: menuItems.history,
      title: t(menuItems.history),
      roles: [Roles.ROLE_USER],
      onClick: () => {},
    },
    {
      className: "material-icons",
      icon: "note",
      link: "/admin/contract",
      name: menuItems.contract,
      title: t(menuItems.contract),
      roles: [Roles.ROLE_ADMIN_PORTAL],
      onClick: () => {},
    },
    {
      className: "material-icons",
      icon: "terrain",
      link: "/gestion-territoriale",
      name: menuItems.territoriale,
      title: t(menuItems.territoriale),
      roles: [Roles.ROLE_ADMIN_PORTAL, Roles.ROLE_SUPER_ADMIN_CONTRACT],
      onClick: () => {},
    },
  ]);

  const getOverlayTooltip = (itemTitle: string, menuIsOpen: boolean) => {
    if (!menuIsOpen) {
      return (
        <Tooltip id="tooltip-right">
          {itemTitle}
        </Tooltip>
      );
    }
    return <div />;
  };

  return (
    <>
      {(error.message && !error.jwtCheck) && <ErrorMessage show={true} />}
      {(error.message && error.jwtCheck) && <ErrorAuthenticationMessage show={true} />}
      <div className="gaia-header-container">
        <Header handleOnClick={() => setIsOpen(!isOpen)} menuIsOpen={isOpen} currentTab={currentTab} />
      </div>
      {connected && (
        <>
          {loading && <Loading />}
          <SzSideMenu className={asideClasses} isOpen={isOpen}>
            <ul className="sz-aside__list list-unstyled m-0">
              {menu.map((item, key) => {
                const granted = RightsHelper.isGranted(user, item.roles) || (0 === item.roles.length)
                return (granted &&
                  <OverlayTrigger
                    key={key}
                    placement="right"
                    delay={{ show: 100, hide: 200 }}
                    overlay={getOverlayTooltip(item.title, isOpen)}
                  >
                    <li key={key} className="d-flex align-items-center" onClick={() => changeTab(item.name)}>
                        <NavLink
                          activeClassName={activeClassName}
                          className="sz-aside__item d-flex align-items-center
                        font-weight-bold text-uppercase text-decoration-none py-2"
                          exact={true}
                          to={item.link}
                          onClick={item.onClick}
                        >
                        <i className={`sz-aside__item--icon ${item.className}`}>{item.icon}</i>
                        <span className={"sz-aside__item--title"}>{item.title}</span>
                      </NavLink>
                    </li>
                  </OverlayTrigger>
                );
              })}
            </ul>
            <div className={"gaia-vision--version-container"}>
              <OverlayTrigger
                placement="right"
                delay={{ show: 100, hide: 200 }}
                overlay={
                  <Tooltip id="tooltip-right">
                    {t("version_control")}
                  </Tooltip>}
              >
                <span>{config.version}</span>
              </OverlayTrigger>
            </div>
          </SzSideMenu>
        </>
      )}
      <div className="gaia-main-container">
        <div className="no-padding container-content">
          <div className={containerClasses}>{children}</div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    connected: state.user.logged,
    user: state.user,
    error: state.error,
    loading: state.search.flag.loading,
    showSearchResult: state.search.flag.showSearchResult,
    showSmartshape: state.search.flag.showSmartshape,
    currentContract: state.contract.currentContract,
    showReport: ReportActions.showReport,
    hideReport: ReportActions.hideReport,
    hideDetail: ReportActions.hideDetail,
    report: state.report,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(ReportActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Container);
